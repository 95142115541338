import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Input, ValidacaoYup } from './../../components/Form'

function LocalizacaoFisica() {

    const validaCampos = {
        descricao: ValidacaoYup.string().required()        
    }

    return (
        <ContainerCad
            title='Localização Física'
            titlesub='Cadastro / Localização Física'
            router='localizacaofisica'
            thead={[
                { nome: 'Descrição' },
                { nome: 'Criado por', tamanho: 150 },
            ]}
            validacao={validaCampos}
        >

            <div className="row">
                <Input name='descricao' titulo='Descrição' placeholder='Digite a descrição' col='col-lg-12' />
            </div>

        </ContainerCad>
    )
}

export default LocalizacaoFisica