import React, { useEffect, useRef, useState } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import axios from 'axios'
import Loading from 'react-loading'
import { Icon } from './../Layout'

function CEP({ name, col, titulo, cidadekey = 'cidade_id', ...props }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [loadBusca, setLoadBusca] = useState(false)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            defaultValue: '',
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });

        const _cep = document.querySelector(`#${name}`)
        _cep.addEventListener('blur', onBuscaCEP)


    }, [fieldName, registerField]);

    async function onBuscaCEP() {

        let cep = inputRef?.current?.value;

        if (!cep || cep.length < 9) return

        setLoadBusca(true)
        const dadosCep = await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^A-Z0-9]+/ig, "")}/json/`)

        const { localidade, uf, bairro, logradouro, complemento } = dadosCep.data

        const style = "width: 100%;background: #FFF;height: 50px;border: 1px solid #e9ecf3;border-radius: 4px;display: flex;justify-content: left;align-items: center;color: #333;padding: 10px;"
        document.querySelector('#cidade_id').innerHTML = `<div id="cidadeSet" cidade="${localidade}" style="${style}">${localidade + ' / ' + uf}</div>`

        document.querySelector('#bairro').value = bairro === undefined ? '' : bairro
        document.querySelector('#rua').value = logradouro === undefined ? '' : logradouro
        document.querySelector('#complemento').value = complemento === undefined ? '' : complemento

        const _numero = document.querySelector(`#numero`)
        if (_numero)
            _numero.focus()

        setLoadBusca(false)
    }

    return (
        <div className={`cInputD ${col}`}>
            <label htmlFor={name}>{titulo}</label>
            <div className='divCep'>
                <ReactInputMask id={name} mask='99999-999' autoComplete='no' ref={inputRef} {...props} {...InputProps} defaultValue={defaultValue} />
                {loadBusca ? <Loading type='spin' color='#CCC' height={20} width={20} className='searchCEPload' /> : <div className='divBuscaCep' onClick={onBuscaCEP}><Icon name='ti-search' /></div>}
                {/*  */}
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default CEP