import React, { useEffect, useRef, useState } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { cnpj } from 'cpf-cnpj-validator'
import { mensagemErro } from './../../components/Layout'

import Loading from 'react-loading'
import api from './../../api'

function CNPJ({ name, col, titulo, search, onSearchCB, ...props }) {

    const inputRef = useRef(null);
    const [loadSearch, setLoadSearch] = useState(false)
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null || value === undefined ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });

        const _cnpj = document.querySelector('#cnpj')
        _cnpj && _cnpj.addEventListener('blur', function () {
            const _clearNumbers = String(_cnpj.value).replace(/\D/g, '');
            if (_clearNumbers.length > 0 && !cnpj.isValid(_clearNumbers)) {
                mensagemErro('Este CNPJ não é válido')
            }
        })

    }, [fieldName, registerField]);

    async function onSearchCNPJ() {

        if (loadSearch) return

        const _clearNumbers = String(inputRef.current.value).replace(/\D/g, '');
        if (_clearNumbers.length > 0 && !cnpj.isValid(_clearNumbers) || _clearNumbers.length < 14) {
            mensagemErro('Este CNPJ não é válido')
            return
        }

        setLoadSearch(true)
        const resp = await api.get(`cnpj/${_clearNumbers}`)

        if (resp.data?.erro) {
            setLoadSearch(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (onSearchCB)
            onSearchCB(resp.data, setLoadSearch)
    }

    return (
        <div className={`cInputD ${col}`} style={{ position: 'relative' }}>
            <label htmlFor={name}>{titulo}</label>
            <ReactInputMask id='cnpj' mask='99.999.999/9999-99' autoComplete='no' ref={inputRef} defaultValue={defaultValue} {...props} />
            {
                search &&
                <div className='divSearchCNPJ'>
                    <div onClick={onSearchCNPJ} className='btnbuscacnpj'>
                        {loadSearch ? <Loading type='spin' color='#CCC' height={20} width={20} className='searchCEPload' /> : <i className="ti-search"></i>}
                    </div>
                </div>
            }
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default CNPJ