import React from 'react'
import './style.scss'

import { Icon } from './../../Layout'

function ButtonNew({ name = '', icon, ...props }) {
    return (
        <>
            <div {...props} className="btn btn-primary btn-block waves-effect waves-light btn-new" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <>
                    {
                        name
                    }
                    {
                        icon ? icon : <Icon name='ti-plus' />
                    }
                </>
            </div>
        </>
    )
}

export default ButtonNew