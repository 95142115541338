import React from 'react';

import Router from './router'
import Store from './context/Store'


function App() {

    return (
        <Store>
            <Router />
        </Store>
    );
}

export default App;
