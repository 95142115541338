import React, { useCallback } from 'react'

import { useAuth } from './../../../context/Auth'
import { useLayout } from './../../../context/Layout'
import { mensagemErro } from './../../Layout'

import { useHistory } from 'react-router-dom'

import api from './../../../api'

function Header({
    router, // Router usada para realizar a pesquisa
    setData, // Data será usada para o retorno da pesquisa
    onSearchCallback, // function de callback quando existe a necessidade de chamar o search de outra tela
}) {

    const history = useHistory()

    const { getUser, removeUser } = useAuth()
    const { isScreenLogin, setOpenMenu, openMenu, setLoadFull } = useLayout()
    const usuario = getUser()

    async function onSair() {

        setLoadFull(true)

        const resp = await api.get('logoff')

        if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        removeUser()
        // isScreenLogin(true)
        window.location.reload()
    }

    function Notificacao() {
        return (
            <li className="dropdown notification-list">
                <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown" href="#" role="button"
                    aria-haspopup="false" aria-expanded="false">
                    <i className="dripicons-bell noti-icon"></i>
                    <span className="badge badge-danger badge-pill noti-icon-badge">2</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                    <h6 className="dropdown-item-text">
                        Notifications (18)
                            </h6>
                    <div className="slimscroll notification-list">
                        <a href="#!" className="dropdown-item notify-item active">
                            <div className="notify-icon bg-success"><i className="mdi mdi-cart-outline"></i></div>
                            <p className="notify-details">Your order is placed<small className="text-muted">Dummy text of the printing and typesetting industry.</small></p>
                        </a>
                        <a href="#!" className="dropdown-item notify-item">
                            <div className="notify-icon bg-warning"><i className="mdi mdi-message"></i></div>
                            <p className="notify-details">New Message received<small className="text-muted">You have 87 unread messages</small></p>
                        </a>
                        <a href="#!" className="dropdown-item notify-item">
                            <div className="notify-icon bg-info"><i className="mdi mdi-glass-cocktail"></i></div>
                            <p className="notify-details">Your item is shipped<small className="text-muted">It is a long established fact that a reader will</small></p>
                        </a>
                        <a href="#!" className="dropdown-item notify-item">
                            <div className="notify-icon bg-primary"><i className="mdi mdi-cart-outline"></i></div>
                            <p className="notify-details">Your order is placed<small className="text-muted">Dummy text of the printing and typesetting industry.</small></p>
                        </a>
                        <a href="#!" className="dropdown-item notify-item">
                            <div className="notify-icon bg-danger"><i className="mdi mdi-message"></i></div>
                            <p className="notify-details">New Message received<small className="text-muted">You have 87 unread messages</small></p>
                        </a>
                    </div>
                    <a href="#!" className="dropdown-item text-center text-primary">
                        View all <i className="fi-arrow-right"></i>
                    </a>
                </div>
            </li>
        )
    }

    function MenuPerfil() {
        return (
            <li className="dropdown">
                <a className="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#!" role="button" aria-haspopup="false" aria-expanded="false">
                    <img src={usuario?.avatar} alt="profile-user" className="rounded-circle" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    {/* <a href='#!' className="dropdown-item"><i className="dripicons-lock text-muted mr-2"></i> Alterar Senha</a> */}
                    {/* <div className="dropdown-divider"></div> */}
                    <a onClick={onSair} className="dropdown-item" href="#!"><i className="dripicons-exit text-muted mr-2"></i> Sair</a>
                </div>
            </li>
        )
    }

    function CloseMenu() {
        return (
            <li>
                <button className="button-menu-mobile nav-link waves-effect waves-light" onClick={() => setOpenMenu(!openMenu)}>
                    <i className="dripicons-menu nav-icon"></i>
                </button>
            </li>
        )
    }

    function Search() {

        const onSearch = useCallback(async (e) => {

            var key = e.which || e.keyCode;
            if (key == 13 && router) {
                setLoadFull(true)

                let resp = null
                const pesquisaText = e.target.value

                if (e.target.value)
                    resp = await api.post(`${router}/pesquisa`, { pesquisa: e.target.value })
                else
                    resp = await api.post(`${router}/dados`, { page: 1 })

                if (setData)
                    setData(resp.data)

                document.querySelector('#serachData').value = pesquisaText
                setLoadFull(false)
            }
        })

        return (
            <li className="hide-phone app-search">
                <input id="serachData" type="text" placeholder="Pesquisa..." className="form-control" onKeyUp={onSearchCallback ? onSearchCallback : onSearch} />
                <a href=""><i className="fas fa-search"></i></a>
            </li>
        )
    }

    function Logo() {
        return (
            <a href='#!' className="logo">
                <span>
                    <img src="../assets/images/logo/logo.svg" alt="logo-large" className="logo-lg" />
                </span>
            </a>
        )
    }

    return (
        <div className="topbar">
            <div className="topbar-left" style={{ marginLeft: openMenu ? 0 : 70, width: openMenu ? 240 : 230 }}>
                <Logo />
            </div>
            <nav className="navbar-custom">
                <ul className="list-unstyled topbar-nav float-right mb-0">
                    {/* <Notificacao /> */}
                    <MenuPerfil />
                </ul>
                <ul className="list-unstyled topbar-nav mb-0">
                    <CloseMenu />
                    <Search />
                </ul>
            </nav>
        </div>
    )
}

export default Header