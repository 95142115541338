import axios from 'axios'
import { authKey } from './../context/Auth'

const api = axios.create({
    baseURL: process.env.REACT_APP_SERV,
})

api.interceptors.request.use(function (config) {
    
    const usuario = JSON.parse(localStorage.getItem(authKey))
    config.headers.Authorization = usuario?.token ? `Bearer ${usuario.token}` : null

    return config;
});

export { axios }
export default api