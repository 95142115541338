import React, { useRef, useEffect } from 'react'
import './style.scss'

import { ButtonSave, ButtonSair } from './../../Form'

function ModalEmpty({
    title,
    load,
    onClose,
    onSave,
    nomeButtonSave = 'Salvar',
    children,
    showButtonSave = true,
    showButtonCancel = true,
    setWidth = '50%'
}) {

    return (
        <>
            <div className="modal fade show">
                <div className="modal-dialog" style={{ width: setWidth }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">{title}</h5>
                            {showButtonCancel && <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>}
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {
                                showButtonCancel &&
                                <ButtonSair name='Sair' onClick={onClose} />
                            }
                            {
                                showButtonSave &&
                                <ButtonSave load={load} name={nomeButtonSave} onClick={onSave} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalEmpty