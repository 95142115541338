import React from 'react'
import './style.scss'

import Spinner from './../Spinner'
import { useLayout } from './../../../context/Layout'
import ReactLoading from 'react-loading'

function Load() {
    const { loadFull } = useLayout()

    return (
        <>
            {
                loadFull &&
                <div className="loadfull">
                    <ReactLoading type='cylon' height={60} color='#20af62' />
                </div>
            }
        </>
    )
}

export default Load