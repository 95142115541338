import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadWidget = React.memo(() => {

    return (
        <div className="col-md-3">
            <div className="card crm-data-card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 align-self-center">
                            <Skeleton circle height={50} width={50} />
                        </div>
                        <div className="col-8">
                            <h3><Skeleton height={24} width={20} /></h3>
                            <p style={{ fontSize: 8, marginBottom: -4, color: '#a4abc5' }}><Skeleton height={7} width={100} /></p>
                            <p className="text-muted font-14 mb-0"><Skeleton height={16} width={60} /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default LoadWidget