import React from 'react'

import { Modal } from './../../components/Layout'
import { Input, ValidacaoYup, Telefone, Celular } from './../../components/Form'

import { v1 as geradorID } from 'uuid'

function Contato({ onClose, dataContato, setDataContato, dataEdit }) {

    function onSave(data) {

        if (!data?.id)
            data.id = geradorID()

        data.delete = false

        /** Verifica se existe */
        const existe = dataContato.filter(contato => String(contato.id) === String(data.id))
        if (existe) {
            let outrosContatos = dataContato.filter(contato => String(contato.id) !== String(data.id))
            outrosContatos.push(data)
            setDataContato(outrosContatos)
        }
        else {
            setDataContato([...dataContato, data])
        }

        onClose()
    }

    const validaCampos = {        
        responsavel: ValidacaoYup.string().required(),
        email: ValidacaoYup.string().required(),
        telefone: ValidacaoYup.string().min(10).required()
    }

    return (
        <Modal
            title='Contato'
            onClose={onClose}
            onSave={onSave}
            validacao={validaCampos}
            nomeButtonSave='Adicionar'
            data={dataEdit}
        >
            <div className="row">
                <Input name='tipo' col='col-lg-4' titulo='Tipo' placeholder='Digite o Tipo' />
                <Input name='responsavel' col='col-lg-8' titulo='Responsável' placeholder='Digite o responsável' />
            </div>

            <div className="row">
                <Input name='email' col='col-lg-12' titulo='E-mail' placeholder='Digite o e-mail' />
            </div>

            <div className="row">
                <Telefone name='telefone' col='col-lg-6' titulo='Telefone' placeholder='Digite o telefone' />
                <Celular name='celular' col='col-lg-6' titulo='Celular' placeholder='Digite o Celular' />
            </div>

            <div className="row">
                <Input name='obs' col='col-lg-12' titulo='Observação' placeholder='Digite sua observação' />
            </div>

        </Modal>
    )
}

export default Contato