import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { Form, Input, Pass, Button, ValidacaoYup } from './../../components/Form'
import { useAuth } from './../../context/Auth'
import { useLayout } from './../../context/Layout'

import api from './../../api'
import './style.scss'

import pacote from './../../../package.json'

import ReCAPTCHA from "react-google-recaptcha";


function Login() {

    const [load, setLoad] = useState(false)
    const [logado, setLogado] = useState(false)
    const [validacao, setValidacao] = useState('')

    const { setUser, getUser } = useAuth()
    const { isScreenLogin } = useLayout()

    const recaptchaRef = React.createRef();

    const validaCampos = {
        email: ValidacaoYup.string().email().required(),
        senha: ValidacaoYup.string().min(6).required()
    }

    async function onLogin(data) {

        recaptchaRef.current.execute()

        setLoad(true)
        const { email, senha } = data

        const resp = await api.post('login', {
            email,
            senha
        })

        if (resp.data.type === 0) {

            const { id, nome, email, token, avatar } = resp.data.value

            /** Adicona o token para futuras requisições */
            api.defaults.headers.Authorization = `Bearer ${token}`

            /** Coloca o usuário logado em Contexto */
            setUser({
                id,
                refresh: id,
                nome,
                email,
                avatar,
                token
            })

            /** Redireciona */
            setLogado(true)
        }
        else if (resp.data.type === 1) {
            setValidacao(resp.data.mensagem)
        }

        setLoad(false)
    }

    const formUser = () => (
        <>
            {
                logado || getUser() ? <Redirect to={{ pathname: '/dash' }} /> :
                    <Form onSubmit={onLogin} className="form-horizontal auth-form my-4" col='input-group mb-3' validacao={validaCampos}>

                        <Input name='email' titulo='E-mail' placeholder='Digite seu e-mail' col='input-group mb-3' />
                        <Pass name='senha' titulo='Senha' placeholder='Digite sua senha' col='input-group mb-3' />

                        <div className="form-group row mt-4">
                            <div className="col-sm-12 text-center">
                                <Link to='/esqueci' className="text-muted font-13"><i className="dripicons-lock"></i> Esqueci senha?</Link>
                            </div>
                        </div>

                        <div className="form-group mb-0 row">
                            <div className="col-12 mt-2">
                                <Button load={load} name='Entrar' icon={<i className="fas fa-sign-in-alt ml-1"></i>} />
                                {validacao && <div className="validaRetorno">{validacao}</div>}
                            </div>
                        </div>

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size='invisible'
                            sitekey="6LeEskMeAAAAAKx_X9fXZ8pJSQ2F4T4pkR5x91m9"
                            onChange={() => { }}
                        />
                    </Form>
            }
            <div className='pacote'>{pacote.version}</div>
        </>
    )

    function ImageLogo() {
        return (
            <div className="auth-logo-box">
                <a href="../analytics/analytics-index.html" className="logo logo-admin">
                    <img src="../assets/images/logo-sm.png" height="55" alt="logo" className="auth-logo" />
                </a>
            </div>
        )
    }

    function CriarCadastro() {
        return (
            <div className="m-3 text-center text-muted">
                <p className="">Ainda não tem uma conta ?  <a href="../authentication/auth-register.html" className="text-primary ml-2">Criar uma nova Conta</a></p>
            </div>
        )
    }

    return (
        <div className="row vh-100">
            <div className="col-12 align-self-center">
                <div className="auth-page login-container">
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                        <div className="imgLogoClass" />
                        <p className='subtit'>GERENCIADOR DE LICENÇAS E CONDICIONANTES</p>
                    </div>
                    <div className="card auth-card shadow-lg">
                        <div className="card-body">
                            <div className="px-3">
                                {formUser()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login