import React from 'react'
import Card from './Card'

import Skeleton from 'react-loading-skeleton'
import { Doughnut } from 'react-chartjs-2'

const GraficoSituacao = React.memo(({ load, dataGraficoSituacao }) => {

    return (
        <Card>
            {
                load ?
                    <Skeleton height={12} width={160} /> :
                    'Situação Licenças'
            }
            <div className='card-grafico-totalizador'>
                {
                    load ?
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginTop: 20 }}>
                                <Skeleton count={3} height={8} width={70} />
                            </div>
                            <div>
                                <Skeleton circle height={85} width={85} />
                            </div>
                        </div> :
                        <Doughnut
                            data={dataGraficoSituacao}
                            legend={{
                                display: true,
                                position: 'left',
                                labels: {
                                    boxWidth: 6,
                                    fontSize: 10
                                }
                            }}
                        />
                }
            </div>
        </Card>
    )
})

export default GraficoSituacao