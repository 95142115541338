import React, { useState, useEffect } from 'react'
import './style.scss'

import Container from './../../components/Container'
import { Button, Input, Pass, Form } from './../../components/Form'
import { mensagemErro, mensagemInfo, mensagemSucesso } from './../../components/Layout'

import { useLayout } from './../../context/Layout'
import { useAuth } from './../../context/Auth'

import api from './../../api'

function Perfil() {

    const { setLoadFull } = useLayout()
    const { getUser, setUser } = useAuth()

    const [data, setData] = useState([])
    const [imageSelected, setImageSelected] = useState('')
    const [imageAvatar, setImageAvatar] = useState('')

    useEffect(() => {

        onBuscaDados()

        const avatarName = getUser().avatar.split('/')
        setImageSelected(avatarName[4])

    }, [])

    async function onBuscaDados() {

        setLoadFull(true)

        const resp = await api.get('avatars')

        if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        setData(resp.data)
        setLoadFull(false)
    }

    function onSelected(item) {

        setImageSelected(item.name)
        setImageAvatar(item.image)
    }

    async function onAtualizar(data) {

        const { senha, repeteSenha } = data

        let dataSend = {}

        if ((senha.length > 0 || repeteSenha.length > 0) && (senha !== repeteSenha)) {
            mensagemInfo('As senhas devem ser iguais')
            return
        }

        if (senha.length > 0 || repeteSenha.length > 0)
            dataSend.pass = data.senha

        if (imageAvatar)
            dataSend.avatar = imageSelected

        if (dataSend.length === 0) return

        setLoadFull(true)

        const usuario = getUser()
        const resp = await api.post('perfil/save', dataSend)

        if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }
        
        usuario.avatar = imageAvatar
        setUser(usuario)

        mensagemSucesso('Perfil atualizado com sucesso!')
        setLoadFull(false)
    }

    return (
        <Container
            title='Perfil'
            titlesub='Configuração / Perfil'
            addClassCard='perfil-card-change'
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',

            }}>

                <div className='perfil-card'>

                    <div className='perfil-avatar'>
                        <img src={getUser().avatar} />
                    </div>

                    <Form onSubmit={onAtualizar}>
                        <Input disabled name='email' tituloe='E-mail' colr='col-lg-6' placeholder='email@email.com' value={getUser().email} />
                        <Pass name='senha' tituloe='Senha' colr='col-lg-6' placeholder='Digite sua senha' />
                        <Pass name='repeteSenha' tituloe='Repete Senha' colr='col-lg-6' placeholder='Confirme sua senha' />

                        <div style={{ width: 420 }}>
                            <div className="text-center auth-logo-text">
                                <h4 className="mt-0 mb-3 mt-5">Selecione um novo Avatar</h4>
                            </div>

                            <div className="avatars">

                                {
                                    data.map((item, index) => {

                                        return (
                                            <img className={imageSelected === item.name ? 'selected' : ''}
                                                key={index}
                                                src={item.image}
                                                onClick={() => onSelected(item)}
                                            />
                                        )
                                    })
                                }

                            </div>
                        </div>

                        <div style={{ marginTop: 12 }}>
                            <Button name='Atualizar' />
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    )
}

export default Perfil