import React from 'react'

function Widget({ vencidas, vencendo, aberta, tickets }) {

    return (
        <>
            <div className="col-md-3">
                <div className="card crm-data-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4 align-self-center">
                                <div className="data-icon">
                                    <i className="ti-clipboard rounded-circle bg-soft-danger"></i>
                                </div>
                            </div>
                            <div className="col-8">
                                <h3>{vencidas}</h3>
                                <p style={{ fontSize: 8, marginBottom: -4, color: '#a4abc5' }}>Condicionantes e Licenças</p>
                                <p className="text-muted font-14 mb-0">Vencidas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card crm-data-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4 align-self-center">
                                <div className="data-icon ">
                                    <i className="ti-receipt rounded-circle bg-soft-success"></i>
                                </div>
                            </div>
                            <div className="col-8">
                                <h3>{vencendo}</h3>
                                <p style={{ fontSize: 8, marginBottom: -4, color: '#a4abc5' }}>Condicionantes e Licenças</p>
                                <p className="text-muted font-14 mb-0">Vencendo/mês</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card crm-data-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4 align-self-center">
                                <div className="data-icon">
                                    <i className="ti-agenda rounded-circle bg-soft-secondary"></i>
                                </div>
                            </div>
                            <div className="col-8">
                                <h3>{aberta}</h3>
                                <p style={{ fontSize: 8, marginBottom: -4, color: '#a4abc5' }}>Condicionantes e Licenças</p>
                                <p className="text-muted font-14 mb-0">Em Aberta</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card crm-data-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-4 align-self-center">
                                <div className="data-icon">
                                    <i className="ti-comments rounded-circle bg-soft-warning"></i>
                                </div>
                            </div>
                            <div className="col-8">
                                <h3>{tickets}</h3>
                                <p style={{ fontSize: 8, marginBottom: -4, color: '#a4abc5' }}>Tickets Abertos</p>
                                <p className="text-muted font-14 mb-0">Tickets</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(Widget)