import React from 'react'
import './style.scss'
import ReactLoading from 'react-loading'

function Paginacao({ load, ...props }) {

    return (
        <div className="row carrega-mais" {...props} >
            {
                load ?
                    <ReactLoading type='cylon' color='#50649c' height={30} width={30} />
                    : <span>
                        `CARREGAR MAIS`
                        <i className="ti-angle-down"></i>
                    </span>
            }
        </div>
    )
}

export default Paginacao 