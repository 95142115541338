import React, { createContext, useContext } from 'react'

const AuthContext = createContext()
const authKey = '@#useramb'

function AuthProvider({ children }) {

    function setUser(dados) {
        localStorage.setItem(authKey, JSON.stringify(dados))
    }

    function getUser() {
        const usuario = JSON.parse(localStorage.getItem(authKey))

        if (!usuario)
            return usuario
        else
            return {
                id: usuario?.id.split('.')[0].replace('$', ''),
                refresh: usuario?.refresh,
                nome: usuario?.nome,
                email: usuario?.email,
                avatar: usuario?.avatar,
                token: usuario?.token
            }
    }

    function getMenu() {
        
        const usuario = JSON.parse(localStorage.getItem(authKey))

        if (usuario) {
            const menuShow = usuario.refresh.substring(usuario.refresh.length - 1)            
            return menuShow
        }
        else {
            return null
        }
    }

    function removeUser() {
        localStorage.removeItem(authKey)
    }

    return (
        <AuthContext.Provider value={{ setUser, getUser, removeUser, getMenu }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext)
    return context
}

export { useAuth, AuthProvider, authKey }