import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import Container from './../../components/Container'
import { mensagemErro } from './../../components/Layout'

import GoogleMapReact from 'google-map-react';

import api from './../../api'
import { useLayout } from './../../context/Layout'

import pin from './pin.svg'

function Mapa() {

    const { loadFull, setLoadFull } = useLayout()

    const [markersAll, setMarkersAll] = useState([])
    const [markers, setMarkers] = useState([])
    const [markerCenter, setMarkerCenter] = useState({})
    const [zoom, setZoom] = useState(4)
    const [markerSelecionado, setMarkerSelecionado] = useState('')

    const mapRef = useRef(null)

    useEffect(() => {
        onBuscaDados()
    }, [])

    async function onBuscaDados() {

        setLoadFull(true)
        const resp = await api.get('cliente/por/mapa')

        if (resp.data?.erro) {

            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        const novoMarkers = resp.data.map(marker => {

            marker.onClick = () => {

                if (mapRef?.current) {
                    mapRef.current.map_.panTo({
                        lat: Number(marker.latitude),
                        lng: Number(marker.longitude)
                    })

                    setMarkerSelecionado(marker)
                }

            }
            return marker
        })

        setMarkers(novoMarkers)
        setMarkersAll(novoMarkers)
    }

    useEffect(() => {

        if (markers.length > 0) {

            setMarkerCenter({
                lat: Number(markers[0].latitude),
                lng: Number(markers[0].longitude),
            })
        }

        setLoadFull(false)

    }, [markers])

    function onApiIsLoaded(map, maps, markers) {

        const bounds = onGetMapBounds(map, maps, markers);
        map.fitBounds(bounds);
        onBindResizeListener(map, maps, bounds);
    };

    function onGetMapBounds(map, maps, markers) {

        const bounds = new maps.LatLngBounds();

        markers.forEach((marker) => {
            bounds.extend(new maps.LatLng(
                marker.latitude,
                marker.longitude,
            ));
        });
        return bounds;
    };

    function onBindResizeListener(map, maps, bounds) {

        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };

    function getMapOptions(maps) {

        return {
            streetViewControl: true,
            scaleControl: true,
            fullscreenControl: true,
            styles: [{
                featureType: "poi.business",
                elementType: "labels",
                stylers: [{
                    visibility: "off"
                }]
            }],
            gestureHandling: "greedy",
            disableDoubleClickZoom: false,
            minZoom: 3,
            maxZoom: 18,

            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID
                ]
            },

            zoomControl: true,
            clickableIcons: true
        };
    }

    function Marker({ marker }) {

        function Callout() {

            return (
                <div className='callout-mapa'>
                    <div className='callout-mapa-titulo' style={{ textTransform: 'uppercase' }} >{marker.nome}</div>
                    <div className='callout-mapa-end1'>{`${marker.rua}, ${marker.numero} - ${marker.complemento}, ${marker.bairro} - ${marker.cidade}/${marker.uf}`}</div>
                    <div className='callout-mapa-resume'>
                        <div className='callout-mapa-resume-cond'>{marker.condicionante}</div>
                        <div className='callout-mapa-resume-licenca'>{marker.licenca}</div>
                    </div>
                </div>
            )
        }

        return (
            <>
                <div style={{ position: 'relative' }} onClick={() => setMarkerSelecionado(marker)}>
                    <img src={pin} alt="Pin" style={{ position: 'absolute', top: -30, left: -7 }} />
                    {markerSelecionado === marker && <Callout />}
                </div>
            </>
        )
    }

    async function onSearch(e) {

        if (e.target.value.length === 0) {
            setMarkers(markersAll)
        }

        var key = e.which || e.keyCode;
        if (key == 13) {
            setLoadFull(true)

            console.log(markersAll)

            const novosMarkers = markersAll.filter(marker => marker.nome.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) !== -1)
            setMarkers(novosMarkers)

            setLoadFull(false)

            document.querySelector('#serachData').value = e.target.value
        }
    }

    return (
        <Container
            title={'Mapa de Clientes'}
            titlesub={'Todos os pontos de clientes pelo mapa '}
            noCard={true}
            clearTemplate='mapa-full-body'
            dataSidebar={markers}
            markerSelecionado={markerSelecionado}
            onSearchCallback={onSearch}
        >

            <div style={{ height: '89%', width: '100%', borderRadius: 8 }}>
                {
                    loadFull ? <div>Carregando...</div> :
                        markers.length === 0 ?
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div className='nofiles' >
                                    <img src='../assets/images/nofiles/nofile-mapa.svg' />
                                    <span>Nenhum cliente disponível</span>
                                </div>
                            </div> :
                            <GoogleMapReact
                                ref={mapRef}
                                bootstrapURLKeys={{ key: 'AIzaSyBVEu7_8UjM1WZTvV7kUU00wmg7SvzXqs4' }}
                                defaultCenter={markerCenter}
                                center={markerCenter}
                                defaultZoom={zoom}
                                yesIWantToUseGoogleMapApiInternals
                                options={getMapOptions}
                                onGoogleApiLoaded={({ map, maps }) => onApiIsLoaded(map, maps, markers)}
                            >

                                {
                                    markers.map(marker => {

                                        return (
                                            <Marker
                                                key={marker.id}
                                                lat={marker.latitude}
                                                lng={marker.longitude}
                                                marker={marker}
                                            />
                                        )
                                    })
                                }

                            </GoogleMapReact>
                }
            </div>

        </Container>
    )
}

export default Mapa