import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

function Telefone({ name, col, titulo, ...props }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null || value === undefined ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    return (
        <div className={`cInputD ${col}`}>
            <label htmlFor={name}>{titulo}</label>
            <ReactInputMask mask='(99) 9999-9999' autoComplete='no' ref={inputRef} defaultValue={defaultValue} {...props} />
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default Telefone