import React from 'react'
import './style.scss'

function ButtonSair({ load, name = 'Sair', icon, ...props }) {

    const styleButton = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
        fontSize: 12
    }

    return (
        <>
            <button {...props} className="btn btn-primary save-btn" style={styleButton} >
                {
                    <>
                        {
                            name
                        }
                        {
                            icon && icon
                        }
                    </>
                }
            </button>
        </>
    )
}

export default ButtonSair