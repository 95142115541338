import React from 'react'

function ButtonAdd({ col = 'col-lg-1', ...props }) {
    return (
        <div className={`button-add-all ${col}`}>
            <div className={`button-add`} {...props}>
                <i class="ti-plus"></i>
            </div>
        </div>
    )
}

export default ButtonAdd