import React, { useState, useEffect } from 'react'
import './style.scss'

import { ButtonNew } from './../../components/Form'
import { mensagemErro, Icon } from './../../components/Layout'
import api from './../../api'

import { useLayout } from './../../context/Layout'
import { useAuth } from './../../context/Auth'

function SelecionarAvatar() {

    const { setLoadFull, loadFull } = useLayout()
    const { getUser, setUser } = useAuth()

    const [data, setData] = useState([])
    const [imageSelected, setImageSelected] = useState('')
    const [imageAvatar, setImageAvatar] = useState('')

    useEffect(() => {
        onBuscaDados()
    }, [])

    async function onBuscaDados() {

        setLoadFull(true)

        const resp = await api.get('avatars')

        if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        setData(resp.data)
        setLoadFull(false)
    }

    function onSelected(item) {

        setImageSelected(item.name)
        setImageAvatar(item.image)
    }

    async function onSelecionar() {

        setLoadFull(true)

        const usuario = getUser()

        const resp = await api.post('avatars/save', {
            avatar: imageSelected
        })

        if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        const _user = getUser()
        setUser({
            ..._user,
            ...{ avatar: imageAvatar }
        })

        window.location.reload();
    }

    return (
        <>
            {
                !loadFull &&
                <div className="row vh-100 selAvatarFull">
                    <div className="col-12 align-self-center">
                        <div className="auth-page">
                            <div className="card auth-card shadow-lg">
                                <div className="card-body">
                                    <div className="px-6">

                                        <div className="text-center auth-logo-text">
                                            <h4 className="mt-0 mb-3 mt-5">Selecione um novo Avatar</h4>
                                            <p className="text-muted mb-0">Basta clicar no que mais te agradar.</p>
                                        </div>

                                        <div className="avatars">

                                            {
                                                data.map((item, index) => {

                                                    return (
                                                        <img className={imageSelected === item.name ? 'selected' : ''}
                                                            key={index}
                                                            src={item.image}
                                                            onClick={() => onSelected(item)}
                                                        />
                                                    )
                                                })
                                            }

                                        </div>

                                        <ButtonNew name='Selecionar e continuar' icon={<Icon />} onClick={onSelecionar} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SelecionarAvatar