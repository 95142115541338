import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Input, ValidacaoYup, SelectAsync } from './../../components/Form'

function Cidade() {

    const validaCampos = {
        nome: ValidacaoYup.string().required()
    }

    return (
        <ContainerCad
            title='Cidade'
            titlesub='Cadastro/Cidade'
            router='cidade'
            thead={[
                { nome: 'Nome' },
                { nome: 'UF', tamanho: 100 }
            ]}
            validacao={validaCampos}
            disabledOnNovo
        >

            <div className="row">
                <Input name='nome' titulo='Nome' placeholder='Digite o Nome' col='col-lg-8' />
                <SelectAsync name='uf_id' titulo='UF' router='uf/combo' col='col-lg-4' />
            </div>

        </ContainerCad>
    )
}

export default Cidade