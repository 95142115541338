import React from 'react'
import { notify } from 'react-notify-toast'

function mensagemSucesso(mensagem) {
    notify.show(mensagem, 'success', 5000, { background: '#2ac471', text: '#FFF' })
}

function mensagemErro(mensagem) {
    notify.show(mensagem, 'error', 5000)
}

function mensagemWarning(mensagem) {
    notify.show(mensagem, 'warning', 5000)
}

function mensagemInfo(mensagem) {
    notify.show(mensagem, 'custom', 5000, { background: '#4795e8', text: '#FFF' })
}

export {
    mensagemSucesso,
    mensagemErro,
    mensagemWarning,
    mensagemInfo
}