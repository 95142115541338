import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Form, Input, Button } from './../../components/Form'
import { mensagemErro, mensagemSucesso } from './../../components/Layout'
import api from './../../api'

import { useLocation, Redirect } from 'react-router-dom'
import { useAuth } from './../../context/Auth'

import ReCAPTCHA from "react-google-recaptcha";

function CadastroCodigo() {

    const [load, setLoad] = useState(false)
    const [logado, setLogado] = useState(false)
    const [validacao, setValidacao] = useState('')

    const location = useLocation()
    const { setUser } = useAuth()

    const recaptchaRef = React.createRef();

    async function onFinishCadastro(data) {

        recaptchaRef.current.execute()

        setLoad(true)

        try {

            const resp = await api.post('login/codigo', {
                email: location.state.email,
                codigo: data.codigo
            })

            if (resp.data.type === 0) {

                const { id, nome, email, token, avatar } = resp.data.value

                /** Adicona o token para futuras requisições */
                api.defaults.headers.Authorization = `Bearer ${token}`

                /** Coloca o usuário logado em Contexto */
                setUser({
                    id,
                    refresh: id,
                    nome,
                    email,
                    avatar,
                    token
                })

                mensagemSucesso('Seja bem vindo ao Ambientar, te enviamos um e-mail com a sua senha!')

                setLoad(false)
                setLogado(true)
            }
            else if (resp.data.type === 1) {
                setValidacao(resp.data.mensagem)
            }

            setLoad(false)

        } catch (error) {

            setLoad(false)
            mensagemErro('Ops, parece que não foi possível recuperar este cadastro. Tente novamente mais tarde!')
            console.log(error);
        }
    }

    return (
        logado ? <Redirect to={{ pathname: '/dash' }} /> :
        <div className="row vh-100 ">
            <div className="col-12 align-self-center">
                <div className="auth-page">
                    <div className="card auth-card shadow-lg">
                        <div className="card-body">
                            <Form onSubmit={onFinishCadastro} className='form-horizontal auth-form my-4'>
                                <div className="px-3">

                                    <div className='cadastro--titulo'>
                                        <label>Finalização do Cadastro</label>
                                        <span>Enviamos um código para seu e-mail</span>
                                        <span>por favor digite o mesmo no campo abaixo</span>
                                    </div>

                                    <Input name='codigo' type='number' titulo='Código' placeholder='Digite o código aqui' />

                                    <div style={{ marginTop: 10 }}>
                                        <Button load={load} name='Finalizar Cadastro' />
                                        {validacao && <div className="validaRetorno">{validacao}</div>}
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size='invisible'
                                    sitekey="6LeEskMeAAAAAKx_X9fXZ8pJSQ2F4T4pkR5x91m9"
                                    onChange={() => { }}
                                />
                            </Form>

                            <div className="m-3 text-center text-muted">
                                <Link to='/' className="text-muted font-13"><i className="dripicons-lock"></i> Voltar para entrar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CadastroCodigo