import React, { useState, useEffect } from 'react'
import './style.scss'

import { Modal, Icon, mensagemErro } from './../../components/Layout'
import { Textarea, ButtonNew } from './../../components/Form'

import api, { axios } from './../../api'
import ReactLoading from 'react-loading'
import nl2br from 'react-nl2br'

function DetalheTicket({ data, setData, onClose }) {

    const [load, setLoad] = useState(true)

    const [loadComentario, setLoadComentario] = useState(false)
    const [dataComentario, setDataComentario] = useState([])
    const [comentario, setComentario] = useState('')

    const cancelToken = axios.CancelToken

    useEffect(() => {
          
        onLoadComentairos()

        return () => {
            cancelToken.source().cancel()
        }

    }, [])

    async function onLoadComentairos() {

        const resp = await api.post('ticket/comentarios', {
            ticket_id: data.id,
            cancelToken: cancelToken.source().token
        })

        if (resp.data?.erro) {
            setLoad(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        setDataComentario(resp.data)
        setLoadComentario(false)
        setComentario('')
        setLoad(false)
    }

    async function onPostComentario() {

        if (loadComentario) return

        setLoadComentario(true)
        const resp = await api.post('ticket/addcomentario', {
            comentario,
            ticket_id: data.id
        })

        if (resp.data?.erro) {
            setLoadComentario(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        onLoadComentairos()
    }

    function Comentario({ comentario }) {

        return (
            <div className="ticket-card ticket-comentario-item" style={{ minHeight: 100 }}>
                <div className="ticket-info-user ticket-info-user-comentario">
                    <div className="ticket-avatar">
                        <img src={comentario.avatar} style={{ width: 40, height: 40, borderRadius: 20 }} />
                        <div className="ticket-avatar-label">
                            <div>{comentario.usuario}</div>
                            <span>{comentario.nivel}</span>
                            <span className='badge badge-boxed badge-soft-info'>{comentario.data}</span>
                        </div>
                    </div>
                    <div className='ticket-infos' style={{ marginLeft: 10 }}>
                    </div>
                </div>
                <div className='ticket-dados'>
                    <div>{nl2br(comentario.comentario, true)}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Modal
                title={data.titulo}
                onClose={onClose}
                setWidth='70%'
                showButtonSave={false}
            >

                <div style={{ padding: 20 }}>

                    {/* DADOS DE QUEM POSTOU */}
                    <div className="card">
                        <div className="card-body">
                            <div className="ticket-card ticket-comentario-header" style={{ minHeight: 100 }}>
                                <div className="ticket-info-user">
                                    <div className="ticket-avatar">
                                        <img src={data.avatar} style={{ width: 40, height: 40, borderRadius: 20 }} />
                                        <div className="ticket-avatar-label">
                                            <div>{data.usuario}</div>
                                            <span>{data.nivel}</span>
                                        </div>
                                    </div>
                                    <div className='ticket-infos'>
                                        <span className='badge badge-boxed badge-soft-info'>{data.tipo}</span>
                                        <span className={`badge badge-boxed badge-soft-${data.status === 'Aberta' ? 'success' : 'danger'}`} style={{ marginLeft: 6 }}>{data.status}</span>
                                    </div>
                                </div>
                                <div className='ticket-dados'>
                                    <span>{data.titulo} <small>{data.data}</small> </span>
                                    <div style={{ marginTop: 10 }}>{data.descricaoFull}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* COMENTARIOS */}
                    <div style={{ paddingTop: 16, paddingLeft: 24, paddingRight: 24, paddingBottom: 22 }}>
                        {
                            load ?
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReactLoading type='cylon' height={60} color='#20af62' />
                                </div>
                                :
                                dataComentario.length > 0 ?
                                    dataComentario.map((comentario, index) => {
                                        return (
                                            <Comentario key={index} comentario={comentario} />
                                        )
                                    }) :
                                    <div className="nofiles">
                                        <img src='../assets/images/nofiles/nofile-comentarios.svg' />
                                    </div>
                        }
                    </div>

                    {/* CAMPO PARA COMENTAR */}
                    {
                        data.status !== 'Fechada' &&
                        <div className='ticket-comentario'>
                            {
                                !load &&
                                <div className='comentario'>
                                    <Textarea disabled={loadComentario} value={comentario} onChange={e => setComentario(e.target.value)} name='comentario' titulo='Comentário' col='col-llg-12' placeholder='Digite seu comentário' rows={5} />
                                    <div style={{ marginTop: 10 }}>
                                        <ButtonNew onClick={onPostComentario} name='Comentar' icon={<Icon name='ti-comments' className='icon-buttonnew' />} />
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>

            </Modal>
        </>
    )
}

export default DetalheTicket