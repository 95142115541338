import React, { useState, useEffect } from 'react'
import './style.scss'

import { ContainerCad } from './../../components/Container'
import { Tab, Icon, mensagemErro, Anexo, Modal } from './../../components/Layout'
import { Input, ValidacaoYup, CPF, CNPJ, Celular, SelectAsync, Select, ButtonNew, Checkbox } from './../../components/Form'

import { cpf, cnpj } from 'cpf-cnpj-validator'
import { v1 as geradorID } from 'uuid'
import ReactLoading from 'react-loading'

import Endereco from './Endereco'
import Contato from './Contato'

function Cliente() {

    /** Endereços */
    const [modalEndereco, setModalEndereco] = useState(false)
    const [dataEndereco, setDataEndereco] = useState([])
    const [dataEditEndereco, setDataEditEndereco] = useState(null)

    /** Contatos */
    const [modalContato, setModalContato] = useState(false)
    const [dataContato, setDataContato] = useState([])
    const [dataEditContato, setDataEditContato] = useState(null)

    /** Importar cliente */
    const [showModalImportCliente, setShowModalImportCliente] = useState(false)
    const [filesImport, setFilesImport] = useState([])

    /** Relad page */
    const [reloadPage, setReloadPage] = useState(false)

    /** Ramo de atividade */
    const [dataRamoAtividade, setDataRamoAtividade] = useState([])

    /** Anexos */
    const [dataAnexos, setDataAnexos] = useState([])
    const [loadAnexo, setLoadAnexo] = useState(false)

    const [dataDOU, setDataDOU] = useState([])

    //** Cnpj */
    const [cpfCnpj, setCpfCnpj] = useState('cnpj')

    /** Valida os Campos */
    const validaCampos = {
        cnpjcpf: ValidacaoYup.string().required(),
        nome: ValidacaoYup.string().required(),
        email: ValidacaoYup.string().email().required()
    }

    // Endereços
    function onNovoEndereco() {
        setDataEditEndereco(null)
        setModalEndereco(true)
    }

    function onEditarEndereco(endereco) {

        endereco.cidade_id = { value: endereco.cidade_id, label: endereco.cidade_nome }

        setDataEditEndereco(endereco)
        setModalEndereco(true)
    }

    function onDeletaEndereco(endereco) {

        const novos = dataEndereco.map(end => {

            if (end.id === endereco.id)
                end.delete = true

            return end
        })

        setDataEndereco(novos)
    }

    function ListaEnderecos() {

        return (
            <div className="row" style={{ paddingLeft: 12, paddingRight: 12 }}>

                <div className='titulo-cliente-list'>
                    <label>Endereço</label>
                    <ButtonNew name='adicionar endereço' onClick={onNovoEndereco} />
                </div>
                {
                    dataEndereco.filter(e => e.delete === false).length > 0 ?
                        dataEndereco.filter(de => de.delete === false).map(e => {
                            return (
                                <div key={e.id} className="list-item-dados">
                                    <div>{e.referencia} - {e.rua}, {e.numero} - {e.bairro}, {e.cidade_nome}</div>
                                    <div className='acaoItens'>
                                        <div className='acaoItensEditar'><span onClick={() => onEditarEndereco(e)}><Icon name='ti-pencil font-16' /> Editar</span></div>
                                        <div className='acaoItensDelete'><span onClick={() => onDeletaEndereco(e)}><Icon name='ti-trash font-16' /> Remover</span></div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className='nofiles-alt'>
                            <img src='../assets/images/nofiles/nofile-endereco.svg' />
                            <span>Nenhum Endereço encontrado</span>
                        </div>
                }
            </div>
        )
    }

    // Contato
    function onEditarContato(contato) {

        setDataEditContato(contato)
        setModalContato(true)
    }

    function onNovoContato() {
        setDataEditContato(null)
        setModalContato(true)
    }

    function onDeletaContato(contato) {

        const novos = dataContato.map(cont => {

            if (cont.id === contato.id)
                cont.delete = true

            return cont
        })

        setDataContato(novos)
    }

    function ListaContatos() {
        return (
            <div className="row" style={{ paddingLeft: 12, paddingRight: 12 }}>

                <div className='titulo-cliente-list'>
                    <label>Contato</label>
                    <ButtonNew name='adicionar contato' onClick={onNovoContato} />
                </div>
                {
                    dataContato.filter(c => c.delete === false).length > 0 ?
                        dataContato.filter(dc => dc.delete === false).map(c => {
                            return (
                                <div key={c.id} className="list-item-dados">
                                    <div>{c.responsavel}{c.tipo && ` / ${c.tipo}`} - Telefones: {c.telefone} {c.celular && ` / ${c.celular}`}</div>
                                    <div className='acaoItens'>
                                        <div className='acaoItensEditar'><span onClick={() => onEditarContato(c)}><Icon name='ti-pencil font-16' /> Editar</span></div>
                                        <div className='acaoItensDelete'><span onClick={() => onDeletaContato(c)}><Icon name='ti-trash font-16' /> Remover</span></div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className='nofiles-alt'>
                            <img src='../assets/images/nofiles/nofile-contato.svg' />
                            <span>Nenhum contato encontrado</span>
                        </div>
                }
            </div>
        )
    }

    // Outros
    function onNovoBefore(setData) {

        setData({ usa_dou: false })

        setDataContato([])
        setDataEndereco([])
        setDataRamoAtividade([])
        setDataAnexos([])
    }

    function onSaveBefore(data) {

        data.enderecos = dataEndereco
        data.contatos = dataContato
        data.ramo_atividade = dataRamoAtividade
        data.anexos = dataAnexos

        data.cnpjcpf = document.querySelector(`#${cpfCnpj}`).value

        if (cpfCnpj === 'cpf') {

            const _clearNumbers = String(data.cnpjcpf).replace(/\D/g, '');
            if (!cpf.isValid(_clearNumbers)) {
                return {
                    error: true,
                    message: 'Informe um CPF Válido'
                }
            }
        }

        if (cpfCnpj === 'cnpj') {

            const _clearNumbers = String(data.cnpjcpf).replace(/\D/g, '');
            if (!cnpj.isValid(_clearNumbers)) {
                return {
                    error: true,
                    message: 'Informe um CNPJ Válido'
                }
            }
        }

        return data
    }

    function onEditarAfter(data, setData, setLoad, setModal) {

        if (data.cliente.cnpjcpf.length === 14)
            setCpfCnpj('cpf')
        else
            setCpfCnpj('cnpj')

        setData(data.cliente)
        setDataEndereco(data.enderecos)
        setDataContato(data.contatos)
        setDataRamoAtividade(data.ramo_atividade)
        setDataAnexos(data.anexos)
        setDataDOU(data.dou)

        setModal(true)
        setLoad(false)
    }

    function validacaoOthers(data) {

        if (dataEndereco.filter(e => e.delete === false).length === 0) {

            mensagemErro('Adicone pelo menos 1 endereço a este Cliente')
            return false
        }

        return data
    }

    /** Ramo de atividade */
    function onChangeRamoAtividade(event) {

        if (!event) return

        const existe = dataRamoAtividade.filter(ramo => +ramo.value === +event.value)
        if (existe.length === 0)
            setDataRamoAtividade([...dataRamoAtividade, event])
    }

    function ListaRamoAtividade() {

        function onDeletaRamoAtividade(ramo) {

            const novalista = dataRamoAtividade.filter(ramoatividade => +ramoatividade.value !== +ramo.value)
            setDataRamoAtividade(novalista)
        }

        return (
            <div className="row" style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 12 }}>
                {
                    dataRamoAtividade.map(ramo => {
                        return (
                            <div key={ramo.value} className="list-item-dados">
                                <div>{ramo?.label}</div>
                                <div className='acaoItens'>
                                    <div className='acaoItensDelete'><span onClick={() => onDeletaRamoAtividade(ramo)}><Icon name='ti-trash font-16' /> Remover</span></div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /** Anexos */
    function ListaAnexos() {

        return (
            <>
                <Anexo files={dataAnexos} setFiles={setDataAnexos} routeDelete='cliente/deleteanexo' />
                {
                    dataAnexos.length === 0 &&
                    <div className='nofiles-alt'>
                        <img src='../assets/images/nofiles/nofile-anexo.svg' />
                        <span>Nenhum Anexo encontrado</span>
                    </div>
                }
            </>
        )
    }

    /** DOU */
    function ListaDOU() {

        return (
            <div className='dou-container'>
                <div style={{
                    width: '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {
                        dataDOU.length === 0 ?
                            <div className='nofiles-alt' style={{ width: 600 }} >
                                <img src='../assets/images/nofiles/nofile-dou.svg' />
                                <span>Nenhum dou encontrado</span>
                            </div> :
                            dataDOU.map(dou => {
                                return (
                                    <div className='dou'>
                                        <div className='dou-header'>
                                            <div>{dou.titulo}</div>
                                            <div className='dou-data'>
                                                <Icon name='ti-calendar' />
                                                {dou.data_consulta}
                                            </div>
                                        </div>
                                        <div className='dou-descricao'>{dou.descricao}</div>
                                        <div className='dou-link'>
                                            <a href={dou.link} target='blank'>
                                                <Icon name='ti-link' />
                                                Link para acesso do DOU
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                <div className='dou-info'>
                    <div className='dou-info-titulo'>
                        <Icon name='ti-alert' />
                        Como funciona
                    </div>
                    <div>
                        O Ambientar realiza suas buscas através do site oficial do Diário Oficial da União (DOU).
                        <br />
                        <br />
                        São realizadas as buscas de todos os seus clientes por CPF ou CNPJ, Razão social ou Número do Processo de maneira
                        assertiva, isto é, com um resultado exato disponível.
                        <br />
                        <br />
                        O Ambientar realiza uma busca geral do seu Cliente para manter um histórico e posterior apenas
                        realiza buscas no DOU diário.
                        <br />
                        <br />
                        Sempre que encontrar algo disponível o Ambientar irá guardar essa informação como mostra ao lado
                        e te avisará enviando um e-mail e/ou notificações através do aplicativo.
                    </div>
                </div>
            </div>
        )
    }

    function Parametros() {

        return (
            <>
                <div className='parametros__cliente'>
                    <div className="row">
                        <Checkbox name='usa_dou' titulo='Usa DOU' col='col-lg-12' />
                        <div className='parametros__item'>
                            Marque esta opção se precisa que o Ambientar busque por este Cliente no Diário oficial da união.
                            <div>Sempre que for citado iremos te avisar via e-mail e notificação no Aplicativo.</div>
                        </div>
                    </div>
                </div>
                <div className='parametros__cliente'>
                    <div className="row">
                        <Checkbox name='recebe_alerta' titulo='Recebe Alerta' col='col-lg-12' />
                        <div className='parametros__item'>
                            Marque esta opção se deseja que este Cliente receba os Alertas
                            de vencimentos de Condicionantes e Licenças vinculadas a ele.</div>
                    </div>
                </div>
            </>
        )
    }

    /** Busca CNPJ */
    async function onBuscaCnpj(data, setLoad) {

        const { nome, cep, logradouro, numero, municipio, email, bairro, uf, complemento } = data

        document.querySelector('#nome').value = nome
        document.querySelector('#nome_fantasia').value = nome
        document.querySelector('#email').value = email

        if (
            cep.length > 0 &&
            logradouro.length > 0 &&
            numero.length > 0 &&
            bairro.length > 0 &&
            municipio.length > 0 &&
            uf.length > 0
        ) {
            setDataEndereco([...dataEndereco, {
                id: geradorID(),
                referencia: 'Matriz',
                cep,
                rua: logradouro,
                numero,
                complemento,
                bairro,
                cidade_id: '',
                cidade_nome: `${municipio} / ${uf}`,
                obs: 'Cadastrado via pesquisa de CNPJ do Ambientar',
                delete: false
            }])
        }

        setLoad(false)
    }

    function ImportClientes() {

        function onImport() {
            setShowModalImportCliente(true)
        }

        return (
            <button data-rh='IMPORTAR' data-rh-at='top' type="button" className="btn btn-primary" onClick={onImport} style={{ marginLeft: 8 }}><Icon name='ti-export' /></button>
        )
    }

    function onCompleteImportacao() {

        setLoadAnexo(false)
        setReloadPage(!reloadPage)
        setShowModalImportCliente(false)
    }

    function FilterComponents() {

        return (
            <>
                <div className='row'>
                    <Input name='nome' titulo='Nome' placeholder='Digite o Nome' col='col-lg-12' maxLength={255} />
                </div>

                <div className='row'>
                    <Input name='email' titulo='E-mail' placeholder='Digite o e-mail' col='col-lg-12' maxLength={255} />
                </div>

                <div className="row">
                    <Input name='ccir' col='col-lg-6' titulo='CCIR' placeholder='Digite o CCIR' maxLength={255} />
                    <Select name='classe' col='col-lg-6' titulo='Classe Ambiental' options={[
                        { value: '1', label: 'Classe 1' },
                        { value: '2', label: 'Classe 2' },
                        { value: '3', label: 'Classe 3' },
                        { value: '4', label: 'Classe 4' },
                        { value: '5', label: 'Classe 5' },
                        { value: '6', label: 'Classe 6' },
                        { value: '7', label: 'Não passível' },
                    ]} />
                </div>

<div className="row">
          <div className="col-lg-4">
            <label style={{ marginBottom: 0, marginTop: 9 }}>Tipo</label>
            <div className="escolheCnpjCpf">
              <div
                onClick={() => setCpfCnpj('cnpj')}
                className={`eCnpj ${cpfCnpj === 'cnpj' && 'active'}`}
              >
                CNPJ
              </div>
              <div
                onClick={() => setCpfCnpj('cpf')}
                className={`eCpf ${cpfCnpj === 'cpf' && 'active'}`}
              >
                CPF
              </div>
            </div>
          </div>

          {cpfCnpj === 'cpf' ? (
            <CPF
              name="cnpjcpf"
              titulo="CPF"
              col="col-lg-8"
              placeholder="000.000.000-00"
            />
          ) : (
            <CNPJ
              name="cnpjcpf"
              titulo="CNPJ"
              col="col-lg-8"
              placeholder="00.000.000/0000-00"
            />
          )}
        </div>
            </>
        )
    }

    return (
        <>
            <ContainerCad
                title='Cliente'
                titlesub='Licença / Cliente'
                router='cliente'
                thead={[
                    { nome: 'Nome' },
                    { nome: 'E-mail' },
                    { nome: 'CNPJ / CPF', tamanho: 170 },
                    { nome: 'Telefone' },
                    { nome: '', tamanho: 50 },
                    { nome: 'Criado por', tamanho: 150 },
                ]}
                validacao={validaCampos}
                validacaoOthers={validacaoOthers}
                onNovoBefore={onNovoBefore}
                onSaveBefore={onSaveBefore}
                onEditarAfter={onEditarAfter}
                moreButtons={[ImportClientes]}
                reload={reloadPage}

                filterComponents={<FilterComponents />}
                showOptionExcel
            >

                <Tab
                    tabs={[
                        {
                            nome: 'Dados',
                            body: (
                                <>
                                    <div className="row">

                                        <div className='col-lg-4'>
                                            <label style={{ marginBottom: 0, marginTop: 9 }}>Tipo</label>
                                            <div className="escolheCnpjCpf">
                                                <div onClick={() => setCpfCnpj('cnpj')} className={`eCnpj ${cpfCnpj === 'cnpj' && 'active'}`}>CNPJ</div>
                                                <div onClick={() => setCpfCnpj('cpf')} className={`eCpf ${cpfCnpj === 'cpf' && 'active'}`}>CPF</div>
                                            </div>
                                        </div>

                                        {
                                            cpfCnpj === 'cpf' ?
                                                <CPF name='cnpjcpf' titulo='CPF' col='col-lg-8' placeholder='000.000.000-00' /> :
                                                <CNPJ name='cnpjcpf' titulo='CNPJ' col='col-lg-8' search onSearchCB={onBuscaCnpj} placeholder='00.000.000/0000-00' />
                                        }

                                    </div>

                                    <div className="row">
                                        <Input name='nome' titulo='Nome' placeholder='Digite o Nome' col='col-lg-12' maxLength={255} />
                                    </div>

                                    <div className="row">
                                        <Input name='nome_fantasia' titulo='Nome Fantasia' placeholder='Digite o Nome Fantasia' col='col-lg-12' maxLength={255} />
                                    </div>

                                    <div className="row">
                                        <Input name='email' titulo='E-mail' placeholder='Digite o e-mail' col='col-lg-12' maxLength={255} />
                                    </div>

                                    <div className="row">
                                        <Input name='inscricao_municipal' type='number' col='col-lg-6' titulo='Inscrição Municipal' placeholder='Digite a Inscrição' maxLength={20} />
                                        <Celular name='telefone' col='col-lg-6' titulo='Celular' placeholder='(00) 0 0000-0000' />
                                    </div>

                                    <div className="row">
                                        <Input name='ccir' col='col-lg-6' titulo='CCIR' placeholder='Digite o CCIR' maxLength={255} />
                                        <Select name='classe' col='col-lg-6' titulo='Classe Ambiental' options={[
                                            { value: '1', label: 'Classe 1' },
                                            { value: '2', label: 'Classe 2' },
                                            { value: '3', label: 'Classe 3' },
                                            { value: '4', label: 'Classe 4' },
                                            { value: '5', label: 'Classe 5' },
                                            { value: '6', label: 'Classe 6' },
                                            { value: '7', label: 'Não passível' },
                                        ]} />
                                    </div>

                                    <div className="row">
                                        <SelectAsync name='ramo_atividade_id' onChange={onChangeRamoAtividade} col='col-lg-12' titulo='Ramo de Atividade' router='ramoatividade/combo' />
                                    </div>

                                    <ListaRamoAtividade />
                                </>
                            )
                        },
                        { nome: 'Endereços', body: <ListaEnderecos /> },
                        { nome: 'Contato', body: <ListaContatos /> },
                        { nome: 'Anexos', body: <ListaAnexos /> },
                        { nome: 'DOU', body: <ListaDOU /> },
                        { nome: 'Parâmetros', body: <Parametros /> },
                    ]}
                />

            </ContainerCad>

            {
                modalEndereco &&
                <Endereco
                    onClose={() => setModalEndereco(false)}
                    dataEndereco={dataEndereco}
                    setDataEndereco={setDataEndereco}
                    dataEdit={dataEditEndereco}
                />
            }

            {
                modalContato &&
                <Contato
                    onClose={() => setModalContato(false)}
                    dataContato={dataContato}
                    setDataContato={setDataContato}
                    dataEdit={dataEditContato}
                />
            }

            {
                showModalImportCliente &&
                <Modal
                    title='Importar Clientes'
                    onClose={() => setShowModalImportCliente(false)}
                    showButtonSave={false}
                    showButtonCancel={!loadAnexo}
                >

                    <Anexo
                        files={filesImport}
                        setFiles={setFilesImport}
                        routeSend='cliente/importar'
                        showButtonDelete={false}
                        descricao='É aceito apenas CSV com até 15mb'
                        accept={['.csv']}
                        oneFile={true}
                        justImport={true}
                        iniciadoCB={() => setLoadAnexo(true)}
                        completeCB={onCompleteImportacao}
                    />

                    {
                        loadAnexo ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <ReactLoading type='bars' color='#20af62' />
                                <p>Aguarde, estamos importando todos seus clientes</p>
                            </div> :
                            <>
                                <div className='download-csv-exemplo-import-cliente'>
                                    <Icon name='ti-download' />
                                    <a href={`${process.env.REACT_APP_SERV}/csv/Exemplo Planilha.csv`} download>Download Planilha Exemplo.csv</a>
                                </div>
                                <div className='download-csv-info'>
                                    <span>Faça download da planilha acima, preenche conforme informado na mesma, depois basta fazer o upload
                                        dela nesta mesma tela. É necessário remover a linha com informações na plnilha de campos opcionais e obrigatórios.</span>
                                </div>
                            </>
                    }

                </Modal>
            }
        </>
    )
}

export default Cliente
