import React, { useMemo } from "react";
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import { useAuth } from '../context/Auth'

import Login from './../pages/Login'
import Cadastro from './../pages/Cadastro'
import CadastroCodigo from './../pages/CadastroCodigo'
import EsqueciSenha from './../pages/EsqueciSenha'
import Dashboard from './../pages/Dashboard'
import SelecionaAvatar from './../pages/SelecionaAvatar'
import Perfil from './../pages/Perfil'

import Cliente from './../pages/Cliente'
import Licenca from './../pages/Licenca'
import Condicionante from './../pages/Condicionante'

import RamoAtividade from './../pages/RamoAtividade'
import Orgao from './../pages/Orgao'
import TipoRegularizacao from './../pages/TipoRegularizacao'
import LocalizacaoFisica from './../pages/LocalizacaoFisica'
import Situacao from './../pages/Situacao'
import Cidade from './../pages/Cidade'
import Uf from './../pages/Uf'

import Moderador from './../pages/Moderador'
import Colaborador from './../pages/Colaborador'
import Ticket from './../pages/Ticket'
import Mapa from './../pages/Mapa'
import Dou from './../pages/Dou'

function Router() {

    return (
        <BrowserRouter>
            <Switch>

                <Route exact path="/" >
                    <Login />
                </Route>

                <Route exact path="/cadastro" >
                    <Cadastro />
                </Route>

                <Route exact path="/codigo" >
                    <CadastroCodigo />
                </Route>

                <Route exact path="/esqueci" >
                    <EsqueciSenha />
                </Route>

                <PrivateRoute path="/dash">
                    <Dashboard />
                </PrivateRoute>

                <PrivateRoute path="/newavatar">
                    <SelecionaAvatar />
                </PrivateRoute>

                <PrivateRoute path="/cliente">
                    <Cliente />
                </PrivateRoute>

                <PrivateRoute path="/licenca">
                    <Licenca />
                </PrivateRoute>

                <PrivateRoute path="/condicionante">
                    <Condicionante />
                </PrivateRoute>

                <PrivateRoute path="/perfil">
                    <Perfil />
                </PrivateRoute>

                <PrivateRoute path="/ramoatividade">
                    <RamoAtividade />
                </PrivateRoute>

                <PrivateRoute path="/orgao">
                    <Orgao />
                </PrivateRoute>

                <PrivateRoute path="/tiporegularizacao">
                    <TipoRegularizacao />
                </PrivateRoute>

                <PrivateRoute path="/localizacaofisica">
                    <LocalizacaoFisica />
                </PrivateRoute>

                <PrivateRoute path="/situacao">
                    <Situacao />
                </PrivateRoute>

                <PrivateRoute path="/cidade">
                    <Cidade />
                </PrivateRoute>

                <PrivateRoute path="/uf">
                    <Uf />
                </PrivateRoute>

                <PrivateRoute path="/colaborador">
                    <Colaborador />
                </PrivateRoute>

                <PrivateRoute path="/moderador">
                    <Moderador />
                </PrivateRoute>

                <PrivateRoute path="/ticket">
                    <Ticket />
                </PrivateRoute>

                <PrivateRoute path="/mapa">
                    <Mapa />
                </PrivateRoute>

                <PrivateRoute path="/dou">
                    <Dou />
                </PrivateRoute>

                {/* Restringe acesso a outras rotas */}
                <Route path="*">
                    <Redirect to={{ pathname: "/" }} />
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

function PrivateRoute({ children, ...rest }) {

    const { getUser, getMenu } = useAuth()
    const usuario = getUser()
    const menuShow = useMemo(() => {
        return getMenu()
    }, [])

    if (usuario && usuario.avatar === '') {

        return (
            <SelecionaAvatar />
        )
    }

    return (
        <Route {...rest} render={({ location, props }) =>
            usuario ?
                validaRota(location, menuShow) ? <Redirect to={{ pathname: "/", state: { from: location } }} /> : children
                :
                <Redirect to={{ pathname: "/", state: { from: location } }} />
        }
        />
    );
}

function validaRota(location, menuShow) {
    
    /** Se não for Admin ( não acessa ) */
    if (location.pathname === '/colaborador' && menuShow !== '1') return true
    else if (location.pathname === '/moderador' && menuShow !== '1') return true
    else if (location.pathname === '/situacao' && menuShow !== '1') return true
    else if (location.pathname === '/cidade' && menuShow !== '1') return true
    else if (location.pathname === '/uf' && menuShow !== '1') return true

    /** Se não for Admin e nem Moderador ( não acessa )  */
    if (location.pathname === '/ramoatividade' && menuShow !== '1' && menuShow !== '2') return true
    else if (location.pathname === '/orgao' && menuShow !== '1' && menuShow !== '2') return true
    else if (location.pathname === '/tiporegularizacao' && menuShow !== '1' && menuShow !== '2') return true
    else if (location.pathname === '/localizacaofisica' && menuShow !== '1' && menuShow !== '2') return true

    return false

}

export default Router
