import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadCondicionante = React.memo(() => {

    return (
        <div className="card">
            <div className="card-body">
                <div className='cond-content'>

                    <div className='cond-tipo-info'>
                        <Skeleton circle width={30} height={30} />
                        <div className='cond-descricao' style={{ marginLeft: 10 }}>
                            <Skeleton width={100} height={16} />
                            <Skeleton width={220} height={10} style={{ marginTop: 10 }} />
                            <Skeleton width={190} height={10} />
                        </div>
                    </div>
                    <div className='cond-badges'>
                        <span className=''><Skeleton height={10} width={60} /></span>
                        <span className='' style={{ marginLeft: 10 }}><Skeleton height={10} width={60} /></span>
                    </div>

                </div>
                <div className='cond-footer'>
                    <div className='cond-user-info'>
                        <Skeleton circle height={20} width={20} />
                        <div className='cond-user-info-dados'>
                            <span><Skeleton height={8} width={120} /></span>
                            <span className='cond-user-nivel'><Skeleton height={8} width={100} /></span>
                        </div>
                    </div>
                    <div className='cond-data-info'>
                        <span><Skeleton height={10} width={60} /></span>
                        <span className='cond-data-from'><Skeleton height={10} width={40} /></span>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default LoadCondicionante