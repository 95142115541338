import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Input, ValidacaoYup } from './../../components/Form'

function Uf() {

    const validaCampos = {
        sigla: ValidacaoYup.string().required(),
        nome: ValidacaoYup.string().required()
    }

    return (
        <ContainerCad
            title='UF'
            titlesub='Cadastro / UF'
            router='uf'
            thead={[
                { nome: 'Nome' },
                { nome: 'Sigla', Tamanhjo: 100 }
            ]}
            validacao={validaCampos}
        >

            <div className="row">
                <Input name='sigla' titulo='sigla' placeholder='Digite a Sigla' col='col-lg-4' />
                <Input name='nome' titulo='Nome' placeholder='Digite o Nome' col='col-lg-8' />
            </div>

        </ContainerCad>
    )
}

export default Uf