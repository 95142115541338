import React from 'react'

import { AuthProvider } from './Auth'
import { LayoutProvider } from './Layout'

import { Load } from './../components/Layout'
import Notifications from 'react-notify-toast';

import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'

function Store({ children }) {

    const ReactHint = ReactHintFactory(React)

    return (
        <>
            <AuthProvider>
                <LayoutProvider>
                    <ReactHint events position='top' />
                    <Load />
                    <Notifications options={{ zIndex: 99999 }} />
                    {children}
                </LayoutProvider>
            </AuthProvider>
        </>
    )
}

export default Store