import React, { useEffect } from 'react'

import { Header, Sidebar, Content } from './../Layout'
import { useLayout } from './../../context/Layout'

import ContainerCad from './ContainerCad'

export default function Container({ children, ...props }) {

    const { isScreenLogin } = useLayout()

    useEffect(() => {
        isScreenLogin(false)
    }, [])

    return (
        <>
            <Header router={props.router} setData={props.setDataSearch} onSearchCallback={props.onSearchCallback} />

            <div className="page-wrapper">
                <Sidebar data={props.dataSidebar} markerSelecionado={props.markerSelecionado} />
                <Content {...props} >
                    {children}
                </Content>
            </div>
        </>
    )
}

export {
    ContainerCad
}