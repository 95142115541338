import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Form, Input, Button } from './../../components/Form'
import api from './../../api'

function EsqueciSenha() {

    const [load, setLoad] = useState(false)
    const [showCodigo, setShowCodigo] = useState(false)
    const [email, setEmail] = useState('')
    const [finish, setFinish] = useState(false)

    async function onReset(data) {

        setLoad(true)

        setEmail(data.email)
        await api.post('login/esqueciSenha', { email: data.email })

        setFinish(false)
        setLoad(false)
        setShowCodigo(true)
    }

    async function onValidCodigo(data) {

        setFinish(false)
        setLoad(true)
        
        const resp = await api.post('login/validaCodigoRecuperacao', {
            codigo: data.codigo,
            email,
        })

        if (resp.data === 1)
            setFinish(true)
        else
            setShowCodigo(false)

    }

    if (finish) {
        return (
            <div className="row vh-100 ">
                <div className="col-12 align-self-center">
                    <div className="auth-page">
                        <div className="card auth-card shadow-lg">
                            <div className="card-body">
                                <div className="px-3">
                                    <div className="text-center auth-logo-text">
                                        <h4 className="mt-0 mb-3 mt-5">Nova Senha</h4>
                                        <p className="text-muted mb-0">Enviamos sua nova senha para seu e-mail, Obrigado!</p>
                                    </div>
                                </div>

                                <div className="m-3 text-center text-muted">
                                    <Link to='/' className="text-muted font-13"><i className="dripicons-lock"></i>Voltar para entrar</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (showCodigo)
        return (
            <div className="row vh-100 ">
                <div className="col-12 align-self-center">
                    <div className="auth-page">
                        <div className="card auth-card shadow-lg">
                            <div className="card-body">
                                <div className="px-3">
                                    <div className="text-center auth-logo-text">
                                        <h4 className="mt-0 mb-3 mt-5">Código Recuperação</h4>
                                        <p className="text-muted mb-0">Enviamos um código para seu e-mail, por favor digite-o no campo abaixo e clique em Validar.</p>
                                    </div>

                                    <Form onSubmit={onValidCodigo} className='form-horizontal auth-form my-4'>
                                        <Input name='codigo' titulo='Código' placeholder='Digite o Código' />

                                        <div style={{ marginTop: 10 }}>
                                            <Button load={load} name='Validar Código' icon={<i className="fas fa-sign-in-alt ml-1"></i>} />
                                        </div>
                                    </Form>
                                </div>

                                <div className="m-3 text-center text-muted">
                                    <Link to='/' className="text-muted font-13"><i className="dripicons-lock"></i> Voltar para entrar</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    else
        return (
            <div className="row vh-100 ">
                <div className="col-12 align-self-center">
                    <div className="auth-page">
                        <div className="card auth-card shadow-lg">
                            <div className="card-body">
                                <div className="px-3">
                                    <div className="text-center auth-logo-text">
                                        <h4 className="mt-0 mb-3 mt-5">Esqueci Senha</h4>
                                        <p className="text-muted mb-0">Digite seu e-mail abaixo, enviaremos um código para ele.</p>
                                    </div>

                                    <Form onSubmit={onReset} className='form-horizontal auth-form my-4'>
                                        <Input name='email' titulo='E-mail' placeholder='Digite seu e-mail' />

                                        <div style={{ marginTop: 10 }}>
                                            <Button load={load} name='Resetar senha' icon={<i className="fas fa-sign-in-alt ml-1"></i>} />
                                        </div>
                                    </Form>
                                </div>

                                <div className="m-3 text-center text-muted">
                                    <Link to='/' className="text-muted font-13"><i className="dripicons-lock"></i> Voltar para entrar</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default EsqueciSenha