import React, { useState, useEffect } from 'react'
import './style.scss'

import Container from './../../components/Container'
import { mensagemErro, Icon } from './../../components/Layout'
import { Paginacao } from './../../components/Layout'

import api, { axios } from './../../api'
import Skeleton from 'react-loading-skeleton'

function Dou() {

    const [loadPage, setLoadPage] = useState(false)
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)

    const [showMore, setShowMore] = useState(true)

    const cancelToken = axios.CancelToken

    useEffect(() => {
        onBuscaDados()

        return () => {
            cancelToken.source().cancel()
        }
    }, [])

    async function onBuscaDados(_page) {
        setLoad(true)

        const resp = await api.post('dou/dados', {
            page: _page ? _page : page,
            cancelToken: cancelToken.source().token
        })

        if (resp.data?.erro) {
            setLoad(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        if (resp.data.length === 0) {
            setShowMore(false)
        }

        if (page !== 1) {
            setData([...data, ...resp.data])
        }
        else {
            setData(resp.data)
        }

        setLoad(false)
        setLoadPage(false)
    }

    async function onSearch(e) {

        var key = e.which || e.keyCode;
        if (key == 13) {

            if (document.querySelector('#serachData').value.length === 0) {
                onBuscaDados()
                return
            }

            setLoad(true)

            const resp = await api.post('dou/pesquisa', {
                pesquisa: document.querySelector('#serachData').value
            })

            if (resp.data?.erro) {
                setLoad(false)
                mensagemErro(`${resp.data.erro.message}`)

                console.clear()
                console.log(resp.data.erro.message)
                console.log(resp.data.erro.error)

                return
            }

            if (resp.status !== 200)
                console.log(resp)

            if (resp.data.length === 0) {
                setShowMore(false)
            }

            if (page !== 1) {
                setData([...data, ...resp.data])
            }
            else {
                setData(resp.data)
            }

            setLoad(false)
            setLoadPage(false)
        }
    }

    function LoadDou() {

        return (
            <div className="card">
                <div className="card-body">
                    <div className='dou-page'>
                        <div className='dou-cliente'>
                            <Skeleton width={20} height={20} style={{ marginRight: 6 }} />
                            <Skeleton width={120} height={20} />
                        </div>
                        <div className='dou-page-header'>
                            <div><Skeleton width={220} height={20} /></div>
                            <div className='dou-page-data'>
                                <Skeleton width={20} height={20} style={{ marginRight: 6 }} />
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <Skeleton width='80%' height={14} />
                            <Skeleton width='40%' height={14} />
                            <Skeleton width={180} height={30} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const DouComp = React.memo(({ dou }) => {
        return (
            <div key={dou.id} className="card">
                <div className="card-body">
                    <div className='dou-page'>
                        <div className='dou-cliente'>
                            <Icon name='ti-user' />
                            {dou.cliente}
                        </div>
                        <div className='buscoupor'>Buscou por: {dou.busca}</div>
                        <div className='dou-page-header'>
                            <div>{dou.titulo}</div>
                            <div className='dou-page-data'>
                                <Icon name='ti-calendar' />
                                {dou.data_consulta}
                            </div>
                        </div>
                        <div className='dou-page-descricao'>{dou.descricao}</div>
                        <div className='dou-page-link'>
                            <a href={dou.link} target='blank'>
                                <Icon name='ti-link' />
                                Link para acesso do DOU
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <Container
            title='DOU'
            titlesub='Integração / Dou'
            noCard={true}
            router='dou'
            onSearchCallback={onSearch}
        >
            <div className='dou-page-container'>
                <div className="dou-page-itens">
                    {
                        load ?
                            <>
                                <LoadDou />
                                <LoadDou />
                                <LoadDou />
                            </> :
                            data.length === 0 ?
                                <div className='nofiles' style={{ width: '100%' }} >
                                    <img src='../assets/images/nofiles/nofile-dou.svg' className='img-no-file-dou' />
                                </div> :
                                data.map(dou => <DouComp key={dou.id} dou={dou} />)
                    }
                    {
                        data?.length >= 10 && !load && showMore &&
                        <Paginacao load={loadPage} onClick={() => {

                            setLoadPage(true)
                            setPage(page + 1)

                            setTimeout(() => {
                                onBuscaDados(page + 1)
                            }, 1000);

                        }} />
                    }
                </div>
                <div className='dou-page-info'>
                    <div className='dou-page-info-titulo'>
                        <Icon name='ti-alert' />
                        Como funciona
                    </div>
                    <div>
                        O Ambientar realiza suas buscas através do site oficial do Diário Oficial da União (DOU).
                        <br />
                        <br />
                        São realizadas as buscas de todos os seus clientes por CPF ou CNPJ, Razão social ou Número do Processo de maneira
                        assertiva, isto é, com um resultado exato disponível.
                        <br />
                        <br />
                        O Ambientar realiza uma busca geral do seu Cliente para manter um histórico e posterior apenas
                        realiza buscas no DOU diário.
                        <br />
                        <br />
                        Sempre que encontrar algo disponível o Ambientar irá guardar essa informação como mostra ao lado
                        e te avisará enviando um e-mail e/ou notificações através do aplicativo.
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Dou