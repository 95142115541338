import React, { useState } from 'react'

function Anexos({ anexos }) {
    const [open, setOpen] = useState(false)

    return (
        <div className={`cond-anexos`}>
            <div className='cond-anexos-title' onClick={() => setOpen(!open)} style={{ borderBottomWidth: 10, borderBottomColor: '#000' }} >
                <span className={`${open && 'cond-anexos-open'}`}>
                    <i className="ti-clip" style={{ marginRight: 4 }} ></i>
                    Anexos
                    <i className={open ? 'ti-angle-up' : 'ti-angle-down'}></i>
                </span>
            </div>
            <div className='cond-anexos-content' hidden={!open}>
                {
                    anexos.map((anexo, index) => {
                        return (
                            <a key={index} href={anexo.url} target='_blank'> <i className="ti-cloud-down"></i> {anexo.nome_original}</a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Anexos