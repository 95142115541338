import React from 'react'

const Card = React.memo(({ children, className }) => {
    return (
        <div className={`card ${className && className}`}>
            <div className="card-body">
                {children}
            </div>
        </div>
    )
})

export default Card