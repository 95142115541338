import React, { useState } from 'react'
import './style.scss'

import Dropzone from 'react-dropzone'
import { Icon, mensagemErro, mensagemSucesso } from './../..//Layout'
import api from './../../../api'

function Anexo({
    files, // Arquivos da lista
    setFiles, // Seta os arquivos da lista
    routeSend = 'uploadanexo', // Rota para envio do anexo
    routeDelete, // Rota para deletar o arquivo
    showButtonDelete = true, // Motra o botão de excluir
    descricao = 'São aceitos apenas PDF, CSV, XLS com até 15mb', // Descrição do que pode ser enviado
    accept = ['.pdf', '.csv', '.xlsx'], // Arquivos aceitos
    oneFile = false, // Apenas um arquivo
    justImport = false, // Deve apenas importar o arquivo
    completeCB, // callback de completado
    iniciadoCB, // callback de inicio
    load, // load 
    showSelectInputNewFile = true
}) {

    const [progressoUpload, setProgressoUpload] = useState(0)

    function onSelectImage(picture) {

        if (picture.length > 0) {

            const formData = new FormData();
            formData.append('file', picture[0])

            if (iniciadoCB)
                iniciadoCB()

            api.post(routeSend, formData, {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: progressEvent => setProgressoUpload(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }).then((response) => {

                setProgressoUpload(0)

                if (justImport) {

                    if (response.data?.erro) {
                        mensagemErro(`${response.data.erro.message}`)

                        console.clear()
                        console.log(response.data.erro.message)
                        console.log(response.data.erro.error)

                        if (completeCB)
                            completeCB()

                        return
                    }

                    mensagemSucesso('Importado com sucesso!')
                }
                else {

                    if (oneFile)
                        setFiles([response.data.file])
                    else
                        setFiles([...files, response.data.file])
                }

                if (completeCB)
                    completeCB()

            }).catch(err => {
                console.log(err)
                mensagemErro('Erro ao enviar este arquivo')
            })
        }
    }

    async function onExcluiAnexo(_file) {

        const novosAnexos = files.filter(file => file !== _file)
        setFiles(novosAnexos)

        const resp = await api.post(routeDelete, { file: _file })
        if (resp.data?.erro) {

            setProgressoUpload(0)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }
    }

    return (
        <Dropzone
            accept={accept}
            maxSize={15728640}
            multiple={false}
            onDrop={onSelectImage}
            disabled={progressoUpload > 0 && progressoUpload <= 100}
        >
            {({ getRootProps, getInputProps }) => (
                <>
                    {
                        showSelectInputNewFile &&
                        <div {...getRootProps()} className='dropzone'>
                            <input {...getInputProps()} />
                            <i className="mdi mdi-upload"></i>
                            <p>Arraste e solte seu arquivo aqui ou clique para selecionar um arquivo</p>
                            <p style={{ fontSize: 9 }}>{descricao}</p>
                        </div>
                    }
                    {
                        !justImport &&
                        files.map((file, index) => {

                            return (
                                <div key={index} className="list-item-dados">
                                    <div>{`${file.nome} (${file.size})`}</div>
                                    <div className='acaoItens'>
                                        <a className='acaoItensEditar' href={file.url} target='_blank'>
                                            <Icon name='ti-eye' style={{ marginRight: 0 }} /> Visualizar
                                        </a>
                                        {showButtonDelete && showSelectInputNewFile && <div className='acaoItensDelete'><span onClick={() => onExcluiAnexo(file)}><Icon name='ti-trash font-16' /> Remover</span></div>}
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        progressoUpload > 0 && progressoUpload <= 100 &&
                        <div className="list-item-dados" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>{`${progressoUpload}%`}</div>
                        </div>
                    }
                </>
            )}
        </Dropzone>
    )
}

export default Anexo