import React, { useState, useEffect } from 'react'
import './style.scss'

import Container from './../../components/Container'
import { mensagemErro, Icon, ModalEmpty, Modal } from './../../components/Layout'
import { SelectAsync } from './../../components/Form'
import api, { axios } from './../../api'
import { useAuth } from './../../context/Auth'

import Skeleton from 'react-loading-skeleton'

import Widget from './Components/Widget'
import LoadWidget from './Components/LoadWidget'
import SemCondicionantes from './Components/SemCondicionantes'
import Condicionante from './Components/Condicionante'
import Licencas from './Components/Licencas'
import LoadCondicionante from './Components/LoadCondicionante'
import GraficoSituacao from './Components/GraficoSituacao'
import Card from './Components/Card'
import DouInfo from './Components/DouInfo'
import GraficoTotalTipo from './Components/GraficoTotalTipo'

function Dashboard() {

    const { getMenu, removeUser } = useAuth()
    const menuShow = getMenu()

    const [load, setLoad] = useState(true)
    const [vencidas, setVencidas] = useState(0)
    const [vencendo, setVencendo] = useState(0)
    const [aberta, setAberta] = useState(0)
    const [tickets, setTickets] = useState(0)

    const [dataGraficoSituacao, setDataGraficoSituacao] = useState({})
    const [dataGrafico, setDataGrafico] = useState({})
    const [dataCondicionante, setDataCondicionante] = useState([])
    const [dataDou, setDataDou] = useState([])

    const [showModalTipo, setShowModaltipo] = React.useState(false)
    const [tipoSelected, settipoSelected] = useState('Todos')

    const [showModalQtd, setShowModalQtd] = React.useState(false)
    const [qtdItens, setQtdItens] = React.useState(10)

    const [showModalCliente, setShowModalCliente] = React.useState(false)
    const [clienteSelected, setClienteSelected] = React.useState({
        id: 0,
        name: 'Selecione um'
    })

    /** Cancelar Busca */
    const cancelToken = axios.CancelToken

    useEffect(() => {
        onBuscaDados()

        return () => {
            cancelToken.source().cancel()
        }
    }, [tipoSelected, qtdItens, clienteSelected])

    async function onBuscaDados() {

        try {

            setLoad(true)

            const resp = await api.get(`dashboard/${tipoSelected}/${qtdItens}/${clienteSelected.id}`, {
                cancelToken: cancelToken.source().token
            })

            if (resp.data?.erro) {
                setLoad(false)
                mensagemErro(`${resp.data.erro.message}`)

                console.clear()
                console.log(resp.data.erro.message)
                console.log(resp.data.erro.error)

                return
            }

            if (resp.status !== 200)
                console.log(resp)

            const { vencidas, vencendo, aberta, condicionantes, tickets, situacao, dou } = resp.data

            setVencidas(vencidas ? vencidas : 0)
            setVencendo(vencendo ? vencendo : 0)
            setAberta(aberta ? aberta : 0)
            setTickets(tickets ? tickets : 0)
            setDataCondicionante(condicionantes)
            setDataDou(dou)

            setDataGrafico({
                labels: [
                    'Vencidas',
                    'Vencendo/mês',
                    'Em Aberta'
                ],
                datasets: [{
                    data: [vencidas, vencendo, aberta],
                    backgroundColor: [
                        '#f1646c',
                        '#24af62',
                        '#4ac7ec'
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#24af62',
                        '#4ac7ec'
                    ]
                }]
            })

            setDataGraficoSituacao({
                labels: situacao.label,
                datasets: [{
                    data: situacao.total,
                    backgroundColor: situacao.cor,
                    hoverBackgroundColor: situacao.cor
                }]
            })

            setLoad(false)

        } catch (error) {

            if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
                mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

                setTimeout(() => {
                    removeUser()
                    window.location.reload()
                }, 3000);
            }
        }
    }

    function FiltroTipo() {

        return (
            <div className='divCardFilter'>
                <div className={`card`}>
                    <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='card-filter-body' onClick={() => setShowModaltipo(true)}>
                            <div>Tipo</div>
                            <span>{tipoSelected}</span>
                        </div>
                        <div
                            onClick={() => settipoSelected('Todos')}
                            className={tipoSelected !== 'Todos' ? 'card-filter-active' : 'card-filter-off'}
                        >
                            {tipoSelected !== 'Todos' ? <Icon name='ti-close' /> : <Icon name='ti-filter' />}
                        </div>
                    </div>
                </div>
                {
                    String(menuShow) !== '4' &&
                    <div className={`card`} style={{ flex: 2 }}>
                        <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className='card-filter-body' onClick={() => setShowModalCliente(true)}>
                                <div>Cliente</div>
                                <span>{clienteSelected.name}</span>
                            </div>
                            <div
                                onClick={() => setClienteSelected({ id: 0, name: 'Selecione um' })}
                                className={Number(clienteSelected.id) !== 0 ? 'card-filter-active' : 'card-filter-off'}
                            >
                                {Number(clienteSelected.id) !== 0 ? <Icon name='ti-close' /> : <Icon name='ti-filter' />}
                            </div>
                        </div>
                    </div>
                }
                <div className={`card`} >
                    <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='card-filter-body' onClick={() => setShowModalQtd(true)}>
                            <div>Itens por página</div>
                            <span>{qtdItens}</span>
                        </div>
                        <div
                            onClick={() => setQtdItens(10)}
                            className={qtdItens !== 10 ? 'card-filter-active' : 'card-filter-off'}
                        >
                            {qtdItens !== 10 ? <Icon name='ti-close' /> : <Icon name='ti-filter' />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const FiltroLoad = React.memo(() => {
        return (
            <div className='divCardFilter'>
                <div className={`card`}>
                    <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span><Skeleton height={18} width={60} /></span>
                        <div className={``}><Skeleton height={20} width={20} /></div>
                    </div>
                </div>
                {
                    String(menuShow) !== '4' &&
                    <div className={`card`} style={{ flex: 2 }}>
                        <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span><Skeleton height={18} width={120} /></span>
                            <div className={``}><Skeleton height={20} width={20} /></div>
                        </div>
                    </div>
                }
                <div className={`card`}>
                    <div className="card-body" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span><Skeleton height={18} width={60} /></span>
                        <div className={``}><Skeleton height={20} width={20} /></div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <Container
                title='Dashboard'
                titlesub='Resumo do Ambientar'
                showWidget
                noCard
            >

                <div className="row">
                    {
                        load ?
                            <>
                                {
                                    [1, 2, 3, 4].map((item, index) => {
                                        return <LoadWidget key={index} />
                                    })
                                }
                            </> :
                            <Widget
                                vencidas={vencidas}
                                vencendo={vencendo}
                                aberta={aberta}
                                tickets={tickets}
                            />
                    }
                </div>

                <div className="row">
                    <div className="col-md-6">
                        {
                            load ?
                                <>
                                    <FiltroLoad />
                                    {
                                        [1, 2, 3].map((item, index) => {
                                            return <LoadCondicionante key={index} />
                                        })
                                    }
                                </>
                                :
                                <>
                                    <FiltroTipo />
                                    {
                                        dataCondicionante.length === 0 ? <SemCondicionantes /> :
                                            dataCondicionante.map((cond, index) => {

                                                return (
                                                    cond.tipo === 'condicionante' ?
                                                        <Condicionante key={index} cond={cond} /> :
                                                        <Licencas key={index} licenca={cond} />
                                                )
                                            })
                                    }
                                </>
                        }
                    </div>
                    <div className="col-md-3">

                        <GraficoTotalTipo
                            load={load}
                            dataGrafico={dataGrafico}
                        />

                        {
                            dataGraficoSituacao?.labels &&
                            <GraficoSituacao
                                load={load}
                                dataGraficoSituacao={dataGraficoSituacao}
                            />
                        }

                    </div>
                    <div className="col-md-3">

                        <DouInfo
                            load={load}
                            dataDou={dataDou}
                        />

                    </div>
                </div>

            </Container>

            {
                showModalTipo &&
                <ModalEmpty
                    title='Selecione um Tipo'
                    showButtonSave={false}
                    onClose={() => setShowModaltipo(false)}
                    setWidth={400}
                >

                    {
                        ['Todos', 'Condicionantes', 'Licenças'].map((tipo, index) => {

                            function onSelecionarTipo(_tipo) {

                                const newTipo = _tipo.length > 5
                                    ? _tipo.substring(0, 4) + '...'
                                    : _tipo

                                settipoSelected(newTipo)
                                setShowModaltipo(false)
                            }                            

                            return (
                                <div onClick={() => onSelecionarTipo(tipo)} key={index}>
                                    <Card className='card-tipo-selecionar'>
                                        <span>{tipo}</span>
                                    </Card>
                                </div>
                            )
                        })
                    }

                </ModalEmpty>
            }

            {
                showModalCliente &&
                <Modal
                    title='Selecione um Cliente'
                    onClose={() => setShowModalCliente(false)}
                    onSave={(data) => {
                        setClienteSelected({
                            id: data.cliente_id,
                            name: data.cliente_nome
                        })
                        setShowModalCliente(false)
                    }}
                    setWidth={500}
                    nomeButtonSave='Filtrar'
                >

                    <SelectAsync
                        name='cliente_id'
                        col='col-lg-12'
                        titulo='Selecione um Cliente'
                        router='cliente/combo'
                        labelValue='cliente_nome'
                    />

                </Modal>
            }

            {
                showModalQtd &&
                <ModalEmpty
                    title='Itens por tela'
                    showButtonSave={false}
                    onClose={() => setShowModalQtd(false)}
                    setWidth={400}
                >

                    {
                        [10, 20, 30, 40, 50].map(qtd => {

                            function onSelecionarQtd(_qtd) {
                                setQtdItens(_qtd)
                                setShowModalQtd(false)
                            }

                            return (
                                <div onClick={() => onSelecionarQtd(qtd)}>
                                    <Card className='card-tipo-selecionar'>
                                        <span>{qtd}</span>
                                    </Card>
                                </div>
                            )
                        })
                    }

                </ModalEmpty>
            }
        </>
    )
}

export default Dashboard