import React from 'react'
import Anexos from './Anexos'

import Parse from 'html-react-parser'

const Licencas = React.memo(({ licenca }) => {

    return (
        <div className="card">
            <div className="card-body">
                <div className='cond-content'>

                    <div className='cond-tipo-info'>
                        {
                            licenca.circleStatus === 'vencida' ?
                                <i className="ti-clipboard rounded-circle bg-soft-danger"></i> :
                                licenca.circleStatus === 'vencendomes' ?
                                    <i className="ti-receipt rounded-circle bg-soft-success"></i> :
                                    <i className="ti-receipt rounded-circle bg-soft-secondary"></i>
                        }
                        <div className='cond-descricao'>
                            {licenca.objeto_licenciado}
                            {licenca.cliente && <div className='cond-cliente'>CLIENTE: {`${licenca.cliente} / ${licenca.clienteemail}`}</div>}
                        </div>
                    </div>
                    <div className='cond-badges'>
                        <span className=''>{Parse(String(licenca.situacao))}</span>
                    </div>

                </div>
                {
                    licenca.listaAnexos.length > 0 && <Anexos anexos={licenca.listaAnexos} />
                }
                <div className='cond-footer'>
                    <div className='cond-user-info'>
                        <img src={licenca.avatar} style={{ width: 20, height: 20, borderRadius: 10 }} />
                        <div className='cond-user-info-dados'>
                            <span>{licenca.usuario}</span>
                            <span className='cond-user-nivel'>{licenca.nivel}</span>
                        </div>
                    </div>
                    <div className='cond-data-info'>
                        <span style={{ color: licenca.circleStatus === 'vencida' && '#f1646c' }}>{licenca.data_validade !== 'Invalid date' && licenca.data_validade}</span>
                        <span className='cond-data-from' style={{ color: licenca.circleStatus === 'vencida' && '#f1646c' }}>{licenca.data_validade_from !== 'Invalid date' && licenca.data_validade_from}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Licencas