import React, { useState, useEffect } from 'react'
import './style.scss'

import { ContainerCad } from './../../components/Container'
import { Tab, Anexo, mensagemErro } from './../../components/Layout'
import { Input, ValidacaoYup, SelectAsync, Data, Checkbox, Textarea, Select, ButtonNew } from './../../components/Form'

import { useAuth } from './../../context/Auth'

function Condicionante() {

    const { getMenu } = useAuth()

    const [bRecorrente, setBRecorrente] = useState(false)
    const [bAlerta, setBAlerta] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    /** Anexos */
    const [dataAnexos, setDataAnexos] = useState([])

    const menuShow = getMenu()

    function onNovoAfter(setData) {

        setData({
            status: { value: 'A', label: 'Aberto' }
        })
    }

    function onNovoBefore() {

        setBRecorrente(false)
        setBAlerta(false)
        setIsEdit(false)
    }

    function onEditarAfter(data, setData, setLoad, setModal) {

        setIsEdit(true)

        setDataAnexos(data.anexos)

        setBRecorrente(data.cond.recorrente)
        setBAlerta(data.cond.alerta)

        setData(data.cond)

        setLoad(false)
        setModal(true)
    }

    function validacaoOthers(data) {

        if (data?.recorrente) {

            if (data?.recorrente_vezes.length === 0) {

                mensagemErro('Preencha a recorrencia vezes')
                return false
            }

            if (data?.recorrente_prazo.length === 0) {

                mensagemErro('Preencha a recorrencia prazo')
                return false
            }

            if (data?.recorrente_tipo.length === 0) {

                mensagemErro('Preencha o tipo da reocrerncia')
                return false
            }

        }

        if (data?.alerta) {

            if (data?.alerta_dias.length === 0) {

                mensagemErro('Preencha a quantidade de dias')
                return false
            }
        }


        return data
    }

    /** Anexos */
    function ListaAnexos() {

        return (
            <>
                <Anexo
                    files={dataAnexos}
                    setFiles={setDataAnexos}
                    routeDelete='condicionante/deleteanexo'
                    showSelectInputNewFile={String(menuShow) !== '4'}
                />

                {
                    dataAnexos.length === 0 &&
                    <div className='nofiles-alt'>
                        <img src='../assets/images/nofiles/nofile-anexo.svg' />
                        <span>Nenhum Anexo encontrado</span>
                    </div>
                }
            </>
        )
    }

    function onSaveBefore(data) {
        data.anexos = dataAnexos
        return data
    }

    function FilterComponents() {

        return (
            <>
                <div className='row'>
                    <SelectAsync
                        name='licenca_id'
                        col='col-lg-12'
                        titulo='Licença vinculada'
                        router='licenca/combo' />
                </div>

                <div className='row'>

                    <Input
                        type='text'
                        name='numero_processo'
                        titulo='Processo'
                        placeholder='Digite o Processo'
                        col='col-lg-3'
                        maxLength={30} />

                    <Input
                        type='text'
                        name='item'
                        titulo='Item'
                        placeholder='0'
                        col='col-lg-2'
                        maxLength={2} />

                    <Input
                        type='text'
                        name='anexo'
                        titulo='Anexo'
                        placeholder='0/A'
                        col='col-lg-3'
                        maxLength={10} />

                    <Select
                        name='status'
                        titulo='Status'
                        col='col-lg-4'
                        options={[
                            { value: 'A', label: 'Aberto' },
                            { value: 'F', label: 'Fechado' },
                        ]} />
                </div>

                <div className='row'>

                    <Data
                        name='data_prazo_inicio'
                        titulo='Data prazo Inicio'
                        col='col-lg-6'
                    />

                    <Data
                        name='data_prazo_final'
                        titulo='Data prazo Final'
                        col='col-lg-6'
                    />

                </div>
            </>
        )
    }

    return (
        <ContainerCad
            title='Condicionate'
            titlesub='Licença / Condicionante'
            router='condicionante'
            disabledOnNovo={String(menuShow) === '4'}
            thead={[
                { nome: 'Cliente' },
                { nome: 'Processo' },
                { nome: 'Item', tamanho: 50 },
                { nome: 'Anexo', tamanho: 50 },
                { nome: 'Data Prazo', tamanho: 200 },
                { nome: '', tamanho: 50 },
                { nome: 'Criado por', tamanho: 150 },
                { nome: 'Status', tamanho: 50 },
            ]}
            validacaoOthers={validacaoOthers}
            onNovoBefore={onNovoBefore}
            onNovoAfter={onNovoAfter}
            onSaveBefore={onSaveBefore}
            onEditarAfter={onEditarAfter}
            validacao={{
                item: ValidacaoYup.number().typeError('É UM CAMPO OBRIGATÓRIO').required(),
                licenca_id: ValidacaoYup.string().min(1).required(),
                anexo: ValidacaoYup.string().max(10).required(),
                status: ValidacaoYup.string().required(),
                descricao: ValidacaoYup.string().required(),
                recorrente: ValidacaoYup.string().notRequired(),
                recorrente_vezes: ValidacaoYup.string().notRequired(),
                recorrente_prazo: ValidacaoYup.string().notRequired(),
                alerta: ValidacaoYup.string().notRequired(),
                alerta_dias: ValidacaoYup.string().notRequired(),
                observacao: ValidacaoYup.string().notRequired(),
                data_prazo: ValidacaoYup.date().typeError('É UM CAMPO OBRIGATÓRIO').required()
            }}

            filterComponents={<FilterComponents />}
            showOptionExcel
        >

            <Tab
                tabs={[
                    {
                        nome: 'Dados', body: (
                            <>
                                <div className="row">
                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='licenca_id'
                                        col='col-lg-12'
                                        titulo='Licença vinculada'
                                        router='licenca/combo'
                                    />
                                </div>

                                <div className="row">
                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        name='item'
                                        titulo='Item'
                                        col='col-lg-6'
                                        placeholder='0'
                                        type='number' />

                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        name='anexo'
                                        titulo='Anexo'
                                        col='col-lg-6'
                                        placeholder='0/A' />
                                </div>

                                <div className="row">
                                    <Textarea
                                        disabled={String(menuShow) === '4'}
                                        name='descricao'
                                        titulo='Descrição'
                                        col='col-lg-12'
                                        placeholder='Digite a descrição da Condicionante'
                                        rows={3} />
                                </div>

                                <div className="row">
                                    <Data
                                        name='data_prazo'
                                        disabled={isEdit}
                                        titulo='Data prazo'
                                        col='col-lg-6' />

                                    <Select
                                        isDisabled={String(menuShow) === '4'}
                                        name='status'
                                        titulo='Status'
                                        col='col-lg-6'
                                        options={[
                                            { value: 'A', label: 'Aberto' },
                                            { value: 'F', label: 'Fechado' }
                                        ]} />
                                </div>

                                <div className="row">
                                    <Textarea
                                        disabled={String(menuShow) === '4'}
                                        name='observacao'
                                        titulo='Observação'
                                        placeholder='Digite a observação'
                                        rows={2}
                                        col='col-lg-12' />
                                </div>

                            </>
                        )
                    },

                    { nome: 'Anexos', body: <ListaAnexos />, hide: !isEdit },

                    !isEdit &&
                    {
                        nome: 'Parâmetros', body: (
                            <>
                                <div className='recorrente__cond'>
                                    <div className="row">
                                        <Checkbox disabled={String(menuShow) === '4'} name='recorrente' titulo='Recorrente' col='col-lg-12' onChange={() => setBRecorrente(!bRecorrente)} />
                                        <div className='explanacao__item'>
                                            Marque esta opção se precisa gerar essa condicionante mais de uma vez.
                                            O Sistema irá realizar tudo automáticamente para você
                                        </div>
                                    </div>

                                    {
                                        bRecorrente &&
                                        <div className="row recorrente-traco">
                                            <Input
                                                disabled={String(menuShow) === '4'}
                                                name='recorrente_vezes'
                                                titulo='Vezes'
                                                col='col-lg-4'
                                                placeholder='0'
                                                type='number' />

                                            <Input
                                                disabled={String(menuShow) === '4'}
                                                name='recorrente_prazo'
                                                titulo='Prazo'
                                                col='col-lg-4'
                                                placeholder='0'
                                                type='number' />

                                            <Select
                                                isDisabled={String(menuShow) === '4'}
                                                name='recorrente_tipo'
                                                titulo='Tipo' col='col-lg-4'
                                                options={[
                                                    { value: 'dia', label: 'Dia' },
                                                    { value: 'mes', label: 'Mês' },
                                                    { value: 'ano', label: 'Ano' },
                                                ]} />
                                        </div>
                                    }
                                </div>

                                <div className='recorrente__cond'>

                                    <div className="row">
                                        <Checkbox
                                            disabled={String(menuShow) === '4'}
                                            name='alerta'
                                            titulo='Alertar sobre o vencimento'
                                            col='col-lg-12'
                                            onChange={() => setBAlerta(!bAlerta)} />

                                        <div className='explanacao__item'>
                                            Marque esta opção para que o sistema te lembre do vencimento desta condicionante
                                            Adicione quantos dias quer ser lembrado antes do vencimento.
                                        </div>
                                    </div>

                                    {
                                        bAlerta &&
                                        <div className="row recorrente-traco">
                                            <Input
                                                disabled={String(menuShow) === '4'}
                                                name='alerta_dias'
                                                titulo='Dias antes do vencimento'
                                                col='col-lg-12'
                                                placeholder='0'
                                                type='number' />
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    }
                ]}
            />

        </ContainerCad>
    )
}

export default Condicionante