import React, { useState, useEffect } from 'react'
import './style.scss'

import Container from './../../components/Container'
import { Modal, mensagemErro } from './../../components/Layout'
import { Input, Textarea, ValidacaoYup, Pass, Select } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import api, { axios } from './../../api'
import Skeleton from 'react-loading-skeleton'

import DetalheTicket from './DetalheTicket'

function Ticket() {

    const { setModalOpen } = useLayout()
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [loadSave, setLoadSave] = useState(false)

    const [showModalDetalhe, setShowModalDetalhe] = useState(false)
    const [dataDetalhe, setDataDetalhe] = useState({})

    const cancelToken = axios.CancelToken

    useEffect(() => {
        onBuscaDados()

        return () => {
            cancelToken.source().cancel()
        }
    }, [])

    async function onBuscaDados() {

        setLoad(true)

        const resp = await api.post('ticket/dados', { page: 0, cancelToken: cancelToken.source().token })
        if (resp.data?.erro) {
            setLoad(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        setData(resp.data)
        setLoad(false)
    }

    async function onSave(data) {

        setLoadSave(true)

        const resp = await api.post('ticket', data)

        if (resp.data?.erro) {
            setLoadSave(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
        }

        if (resp.status !== 200)
            console.log(resp)

        onBuscaDados()
        setShowModal(false)
        setLoadSave(false)
    }

    function onShowDetalhes(ticket) {

        if (load) return

        setDataDetalhe(ticket)
        setModalOpen(true, () => setShowModalDetalhe(true))
    }

    const Ticket = React.memo(({ ticket }) => {
        return (
            <div key={ticket.id} className="card" onClick={() => onShowDetalhes(ticket)}>
                <div className="card-body">
                    <div className="ticket-card">
                        <div className="ticket-info-user">
                            <div className="ticket-avatar">
                                <img src={ticket.avatar} style={{ width: 40, height: 40, borderRadius: 20 }} />
                                <div className="ticket-avatar-label">
                                    <div>{ticket.usuario}</div>
                                    <span>{ticket.nivel}</span>
                                </div>
                            </div>
                            <div className='ticket-infos'>
                                <span className='badge badge-boxed badge-soft-info'>{ticket.tipo}</span>
                                <span className={`badge badge-boxed badge-soft-${ticket.status === 'Aberta' ? 'success' : 'danger'}`} style={{ marginLeft: 6 }}>{ticket.status}</span>
                            </div>
                        </div>
                        <div className='ticket-dados'>
                            <span>{ticket.titulo} <small>{ticket.data}</small> </span>
                            <div>{ticket.descricao}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    function LoadTicket() {

        return (
            <div className="card">
                <div className="card-body">
                    <div className="ticket-card">
                        <div className="ticket-info-user">
                            <div className="ticket-avatar">
                                <Skeleton circle height={40} width={40} />
                                <div className="ticket-avatar-label" style={{ marginLeft: 10 }}>
                                    <div><Skeleton width={80} height={18} /></div>
                                    <span><Skeleton width={60} style={{ marginTop: 10 }} /></span>
                                </div>
                            </div>
                            <div className='ticket-infos'>
                                <span><Skeleton width={50} /></span>
                                <span style={{ marginLeft: 6 }}><Skeleton width={50} /></span>
                            </div>
                        </div>
                        <div className='ticket-dados'>
                            <span><Skeleton width={140} height={20} /> <small><Skeleton width={60} height={12} style={{ marginLeft: 8 }} /></small></span>
                            <div><Skeleton width={300} height={10} style={{ marginTop: 6 }} /></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Container
                title='Ticket'
                titlesub='Configuração / Ticket'
                onNovo={() => setShowModal(true)}
                noCard={true}
                router='ticket'
                setDataSearch={setData}
            >

                {
                    load ?
                        <>
                            <LoadTicket />
                            <LoadTicket />
                            <LoadTicket />
                        </> :
                        data.length === 0 ?

                            <div className="nofilestable" style={{ height: '80%' }}>
                                <img src='assets/images/nofiles/nofile-table.svg' />
                            </div> :

                            data.map((ticket, index) => {

                                return <Ticket key={index} ticket={ticket} />
                            })
                }

            </Container>
            {
                showModal &&
                <Modal
                    title={`Novo Ticket`}
                    nomeButtonSave='Abrir'
                    load={loadSave}
                    onSave={onSave}
                    onClose={() => setShowModal(false)}
                    validacao={{
                        titulo: ValidacaoYup.string().required(),
                        tipo: ValidacaoYup.string().required(),
                        descricao: ValidacaoYup.string().required(),
                    }}
                >
                    <>
                        <div className="row">
                            <Input name='titulo' titulo='Título' col='col-lg-8' placeholder='Digite o Titulo' />
                            <Select name='tipo' titulo='Tipo' col='col-lg-4' options={[
                                { value: 'D', label: 'Dúvida' },
                                { value: 'B', label: 'Bug' },
                            ]} />
                        </div>
                        <div className="row">
                            <Textarea name='descricao' titulo='Descrição' col='col-lg-12' placeholder='Digite o que está acontecendo' rows={6} />
                        </div>
                    </>
                </Modal>
            }
            {
                showModalDetalhe &&
                <DetalheTicket
                    data={dataDetalhe}
                    setData={setDataDetalhe}
                    onClose={() => setModalOpen(false, () => setShowModalDetalhe(false))}
                />
            }
        </>
    )
}

export default Ticket