import React, { useRef, useEffect } from 'react'
import './style.scss'

import { ButtonSave, ButtonSair, Form, Input } from './../../Form'

function Modal({
    title,
    load,
    onClose,
    onSave,
    validacao,
    data,
    nomeButtonSave = 'Salvar',
    children,
    showButtonSave = true,
    showButtonCancel = true,
    setWidth = '50%'
}) {

    const formRef = useRef()

    useEffect(() => {


        if (data && formRef?.current) {

            formRef.current.reset()
            if (data) {
                formRef.current.setData(data)
            }
        }

    }, [])

    function _onClose() {

        if (load) return

        if (onClose)
            onClose()
    }

    return (
        <>
            <Form setRef={formRef} onSubmit={onSave} validacao={validacao} >
                <div className="modal fade show">
                    <div className="modal-dialog" style={{ width: setWidth }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myLargeModalLabel">{title}</h5>
                                {showButtonCancel && <button onClick={_onClose} type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>}
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <Input name='id' hidden col='col-lg-12' />
                                </div>

                                {children}
                            </div>
                            <div className="modal-footer">
                                {
                                    showButtonCancel &&
                                    <ButtonSair name='Sair' onClick={_onClose} />
                                }
                                {
                                    showButtonSave &&
                                    <ButtonSave load={load} name={nomeButtonSave} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default Modal