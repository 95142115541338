import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactSelect, { OptionTypeBase, Props as SelectProps, } from 'react-select';

function Select({ name, titulo, col, ...props }) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'state.value',
            getValue: (ref) => {
                if (props.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                } else {
                    if (!ref.state.value) {
                        return '';
                    }
                    return ref.state.value.value;
                }
            },
            setValue: (ref, value) => {
                ref.select.setValue(value);
            },
        });
    }, [fieldName, registerField, props.isMulti]);

    return (
        <div className={`cInputD selectD ${col}`}>
            <label htmlFor={name}>{titulo}</label>
            <ReactSelect
                id={name}
                defaultValue={defaultValue}
                ref={selectRef}
                placeholder='Selecionar'
                classNamePrefix="react-select"
                isClearable
                noOptionsMessage={() => 'Sem dados'}
                theme={theme => ({
                    ...theme,                    
                    colors: {
                        ...theme.colors,
                        primary25: '#179c55',
                        primary: '#179c55',
                        primary50: 'rgba(23, 156, 85, 0.2)',                        
                    },                    
                })}
                {...props}
            />
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default Select