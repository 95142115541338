import React from 'react'
import { Icon } from './../../../components/Layout'

const SemCondicionantes = React.memo(() => {

    return (
        <div className='noCondLicenca'>
            <Icon name='ti-receipt' />
            <span>Nenhum item encontrada</span>
        </div>
    )
})

export default SemCondicionantes