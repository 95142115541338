import React, { useRef, useEffect, useState } from 'react';
import Select, { Props as AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';
import api from './../../api'

function SelectAsync({ name, col, titulo, router, labelValue = '', change_id = '', ...props }) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    const [textSelecionad, setTextSelecionado] = useState('')

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'select.state.value',
            getValue: (ref) => {
                if (props.isMulti) {
                    if (!ref.select.state.value) {
                        return [];
                    }

                    return ref.select.state.value.map(
                        (option) => option.value,
                    );
                } else {
                    if (!ref.select.state.value) {
                        return '';
                    }

                    return ref.select.state.value.value;
                }
            },
            setValue: (ref, value) => {
                ref.select.select.setValue(value);
            }
        });
    }, [fieldName, registerField, props.isMulti]);

    async function onSearchData(seachText) {

        if (router) {

            const resp = await api.post(router, {
                text: seachText,
                id: change_id
            })

            if (resp.status === 200) {
                return resp.data
            }
            else {
                return []
            }
        }
    }

    return (
        <div className={`cInputD selectD ${col}`} classID={labelValue}>
            <label htmlFor={name}>{titulo}</label>
            <Select
                id={name}
                cacheOptions
                defaultValue={defaultValue}
                ref={selectRef}
                classNamePrefix="react-select"
                placeholder='Selecionar'
                noOptionsMessage={() => 'Sem dados'}
                loadOptions={onSearchData}
                isClearable
                defaultOptions
                theme={theme => ({
                    ...theme,                    
                    colors: {
                        ...theme.colors,
                        primary25: '#179c55',
                        primary: '#179c55',
                        primary50: 'rgba(23, 156, 85, 0.2)',                        
                    },                    
                })}
                {...props}
            />
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
};

export default SelectAsync;