import React, { useState, useEffect } from 'react'
import './style.scss'

import Container from './index'
import { Table, Modal, Paginacao, mensagemErro, mensagemSucesso, mensagemInfo } from './../../components/Layout'

import api, { axios } from './../../api'
import { useLayout } from './../../context/Layout'
import { useAuth } from './../../context/Auth'

function ContainerCad({
   children,
   router,
   title,
   titlesub = '',
   thead,
   nofileimage,
   validacao,
   validacaoOthers,
   disabledOnNovo,
   onNovoBefore, // setData
   onNovoAfter,
   moreButtons = [],
   onSaveBefore,
   onEditarAfter, // data, setData, setLoad, showModal
   enabledFormData = false,
   reload, // Atualiza os dados da pagina   
   filterComponents: FilterComponents,
   showOptionExcel = false
}) {

   const { loadFull, setLoadFull } = useLayout()
   const { removeUser } = useAuth()

   const [loadSave, setLoadSave] = useState(false)
   const [showModal, setShowModal] = useState(false)
   const [tableData, setTableData] = useState([])
   const [dataEdit, setDataEdit] = useState()
   const [page, setPage] = useState(1)

   const [showButtonSaveModal, setShowButtonSaveModal] = useState(true)
   const [valuePesquisa, setValuePesquisa] = useState('')
   const [showCarregaMais, setShowCarregaMais] = useState(true)

   const [dataFilter, setDataFilter] = React.useState('')
   const [isFilterInfo, setIsFilterInfo] = React.useState('')

   const cancelToken = axios.CancelToken

   useEffect(() => {

      onBuscaDados(true)

      return () => {
         cancelToken.source().cancel()
      }
   }, [reload])

   async function onBuscaDados(_clearPage) {

      try {

         if (!router) return

         setLoadFull(true)

         const resp = await api.post(`${router}/dados`, {
            page: _clearPage ? _clearPage : page,
            cancelToken: cancelToken.source().token
         })

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         setShowCarregaMais(resp.data.length !== 0)

         if (_clearPage)
            setTableData(resp.data)
         else
            setTableData([...tableData, ...resp.data])

         if (_clearPage)
            setPage(_clearPage + 1)
         else
            setPage(page + 1)

         setLoadFull(false)

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   function onClearFilter() {

      setIsFilterInfo('')
      setDataFilter('')
      onBuscaDados(true)
   }

   async function onFilter(data, onCloseModal, onIsLoading) {

      try {

         let notFilter = true

         // Valida se pelo menos um campo está preenchido
         for (var [_, value] of Object.entries(data)) {

            if (value !== '' && value !== null)
               notFilter = false
         }

         if (notFilter) {

            onIsLoading()
            mensagemInfo('Preencha pelo menos um campo para filtrar os dados')
            return
         }

         // Guarda o filtro para exportar excel filtrado
         setDataFilter(data)

         // Requere os dados filtrados
         const resp = await api.post(`${router}/filter`, {
            filter: data,
            page: 0,
            cancelToken: cancelToken.source().token
         })

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)
            onIsLoading()

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         setShowCarregaMais(resp.data.length !== 0)
         setTableData(resp.data)

         setIsFilterInfo('Os dados em exibição estão sendo filtrados neste momento')

         onIsLoading()
         onCloseModal()

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }

         onIsLoading()
         setLoadFull(false)
      }
   }

   async function onExportExcel() {

      try {

         setLoadFull(true)
         const resp = await api.post(`${router}/excel`, {
            filter: dataFilter
         })

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         const filenameExcel = resp.config.url.replace('/excel', '')
         const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${resp.data}`);
         const link = document.createElement("a");
         link.setAttribute("href", encodedUri);
         link.setAttribute("download", `${filenameExcel}.csv`);
         document.body.appendChild(link);
         link.click();

         setLoadFull(false)
         mensagemSucesso('Dados exportados com sucesso!')

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   function onNovo() {

      setShowButtonSaveModal(true)

      if (onNovoBefore)
         onNovoBefore(setDataEdit)

      setDataEdit([])
      setShowModal(true)

      if (onNovoAfter)
         onNovoAfter(setDataEdit)
   }

   async function onSave(data) {

      try {

         if (validacaoOthers) {
            const _data = validacaoOthers(data)

            if (_data === false) return

            data = _data
         }

         setLoadSave(true)

         if (onSaveBefore)
            data = onSaveBefore(data)

         // valida retorno 
         if (data?.error === true) {
            mensagemErro(data?.message)
            setLoadSave(false)
            return
         }

         if (!router) {

            console.clear()
            console.log('data')
            console.log(data)

            setLoadSave(false)

            return
         }

         let resp = []

         //** Usa formData */
         if (enabledFormData) {

            var form_data = new FormData();
            for (var key in data) {
               form_data.append(key, data[key]);
            }

            if (data?.id)
               resp = await api.put(`${router}/${data.id}`, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })
            else
               resp = await api.post(router, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })
         }
         else {
            if (data?.id)
               resp = await api.put(`${router}/${data.id}`, data)
            else
               resp = await api.post(router, data)
         }

         if (resp.data?.erro) {
            setLoadSave(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         if (valuePesquisa.length > 0)
            onPesquisa(valuePesquisa)
         else
            onBuscaDados(1)

         setShowModal(false)
         setLoadSave(false)

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   async function onEditar(id, showSave) {

      try {

         setLoadFull(true)

         const resp = await api.get(`${router}/${id}`)

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         setShowButtonSaveModal(showSave)

         if (onEditarAfter) {

            onEditarAfter(resp.data, setDataEdit, setLoadFull, setShowModal)
         }
         else {

            setDataEdit(resp.data)
            setLoadFull(false)
            setShowModal(true)
         }

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   async function onDelete(id) {

      try {

         setLoadFull(true)

         const resp = await api.delete(`${router}/${id}`)

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200 && resp.status !== 204)
            console.log(resp)

         const data = tableData.filter(t => +t.id !== +id)
         setTableData(data)

         mensagemSucesso('Item deletado com sucesso!')

         setLoadFull(false)

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   async function onPesquisa(value) {

      try {

         setLoadFull(true)
         setValuePesquisa(value)

         if (!value || value.length === 0) {
            onBuscaDados(1)
            return
         }

         const resp = await api.post(`${router}/pesquisa`, { pesquisa: value })

         if (resp.data?.erro) {
            setLoadFull(false)
            mensagemErro(`${resp.data.erro.message}`)

            console.clear()
            console.log(resp.data.erro.message)
            console.log(resp.data.erro.error)

            return
         }

         if (resp.status !== 200)
            console.log(resp)

         setTableData(resp.data)
         setLoadFull(false)
         document.querySelector('#input-pesquisa').value = value

      } catch (error) {

         if (error?.response?.data?.error?.name === 'ExpiredJwtToken') {
            mensagemErro(`Sua sessão expirou. Você será redirecionado para realizar seu login novamente em 3 segundos.`)

            setTimeout(() => {
               removeUser()
               window.location.reload()
            }, 3000);
         }
      }
   }

   return (
      <>
         <Container
            title={title}
            titlesub={titlesub}
            onNovo={!disabledOnNovo && onNovo}
            // onPesquisa={onPesquisa}
            noCard={tableData.length === 0}
            moreButtons={moreButtons}
            router={router}
            setDataSearch={setTableData}

            // FILTRAR DATA
            filterData={{
               Component: FilterComponents,
               callback: onFilter,
               filtrando: isFilterInfo.length > 0,
               onClearFilter: onClearFilter
            }}

            excel={{
               showOptionExcel: showOptionExcel,
               callback: onExportExcel
            }}
         >

            {
               isFilterInfo &&
               <div className='container-crud--filter-info'>
                  <span>{isFilterInfo}</span>
               </div>
            }

            {
               tableData.length > 0 ?
                  <Table
                     thead={thead}
                     tbody={tableData}
                     onEditar={onEditar}
                     onDelete={onDelete}
                     nofileimage={nofileimage}
                  />
                  :
                  <>
                     {
                        !loadFull &&
                        <div className="nofilestable" style={{ height: '80%' }}>
                           <img src='assets/images/nofiles/nofile-table.svg' />
                        </div>
                     }
                  </>
            }

            {
               showCarregaMais &&
               tableData.length >= 10 &&
               valuePesquisa.length === 0 &&
               isFilterInfo.length === 0 &&
               <Paginacao load={loadFull} onClick={() => onBuscaDados()} />
            }

         </Container>

         {
            showModal &&
            <Modal
               title={`Novo ${title}`}
               load={loadSave}
               onSave={onSave}
               onClose={() => setShowModal(false)}
               validacao={validacao}
               data={dataEdit}
               showButtonSave={showButtonSaveModal}
            >
               {children}
            </Modal>
         }
      </>
   )
}

export default ContainerCad