import React from 'react'
import './style.scss'

import { Icon, Modal } from '../../Layout'

function Content({
    children,
    title,
    titlesub = '',
    onNovo,
    onPesquisa,

    noCard,
    addClassCard = '',
    moreButtons = [],
    clearTemplate,
    filterData,
    excel
}) {

    const [showModalFilter, setShowModalFilter] = React.useState(false)
    const [isLoaadingFilter, setIsLoadingFilter] = React.useState(false)

    function _onNovo() {

        if (onNovo)
            onNovo()
    }

    function _onPesquisa() {

        const pesquisa = document.querySelector('#input-pesquisa').value

        if (onPesquisa)
            onPesquisa(pesquisa)
    }

    function TituloPage() {
        return (
            <div className="col-lg-6" style={{ display: 'flex', alignItems: 'center' }}>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <h4 className="page-title" style={{ marginTop: 6 }}>{title}</h4>
                        {titlesub.length > 0 && <small className='content-breadcrumb'>{titlesub}</small>}
                    </li>
                </ul>
            </div>
        )
    }

    function BotaoNovoPesquisa() {
        return (
            <div className="col-lg-6 text-right">
                <div className="text-right">
                    {
                        <>
                            <ul className="list-inline">
                                {
                                    onPesquisa &&
                                    <li className="list-inline-item">
                                        <div className="input-group">
                                            <input type="text" id="input-pesquisa" name="example-input1-group2" className="form-control" placeholder="Pesquisar" />
                                            <span className="input-group-append">
                                                <button onClick={_onPesquisa} type="button" className="btn btn-primary"><i className="fas fa-search"></i></button>
                                            </span>
                                        </div>
                                    </li>
                                }
                                {
                                    onNovo &&
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-primary" onClick={_onNovo}>Adicionar novo</button>

                                        {
                                            filterData.Component &&
                                            <button
                                                data-rh={filterData.filtrando ? 'LIMPAR FILTRO' : 'FILTRAR'}
                                                data-rh-at='top'
                                                type="button"
                                                className={`btn btn-primary ${filterData.filtrando ? 'content--filter-close' : ''}`}
                                                onClick={() => {

                                                    if (filterData.filtrando) {
                                                        filterData.onClearFilter()
                                                    }
                                                    else {
                                                        setShowModalFilter(true)
                                                    }

                                                }}
                                                style={{ marginLeft: 8 }}>

                                                {
                                                    filterData.filtrando
                                                        ? <Icon name='ti-close' />
                                                        : <Icon name='ti-filter' />
                                                }
                                            </button>
                                        }

                                        {
                                            excel.showOptionExcel &&
                                            <button
                                                data-rh='EXPORTAR EXCEL'
                                                data-rh-at='top'
                                                type="button"
                                                className={`btn btn-primary`}
                                                style={{ marginLeft: 8 }}
                                                onClick={excel.callback}
                                            >
                                                <Icon name='ti-import' />
                                            </button>
                                        }

                                        {
                                            moreButtons.map((Button, index) => {
                                                return <Button key={index} />
                                            })
                                        }
                                    </li>
                                }
                            </ul>
                        </>
                    }
                </div>
            </div>
        )
    }

    if (clearTemplate) {

        return (
            <div className={`page-content ${clearTemplate}`}>
                {children}
            </div>
        )
    }
    else {

        return (
            <>
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row">
                                        <TituloPage />
                                        <BotaoNovoPesquisa />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !noCard ?
                            <div className={`card ${addClassCard}`}>
                                <div className="card-body">
                                    {children}
                                </div>
                            </div>
                            :
                            <>
                                {children}
                            </>
                    }


                    <footer className="footer text-center text-sm-left">
                        &copy; 2020 Ambientar
                        <span className="text-muted d-none d-sm-inline-block float-right">
                            by Tech Consultoria
                        </span>
                    </footer>
                </div>

                {
                    showModalFilter && filterData.Component &&
                    <Modal
                        title='Filtrar'
                        onClose={() => setShowModalFilter(false)}
                        nomeButtonSave='Filtrar'
                        load={isLoaadingFilter}
                        onSave={(data) => {

                            setIsLoadingFilter(true)

                            filterData.callback(
                                data,
                                () => setShowModalFilter(false),
                                () => setIsLoadingFilter(false),
                            )
                        }}
                    >
                        {filterData.Component}
                    </Modal>
                }
            </>
        )
    }
}

export default Content