import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Tab, Anexo, mensagemErro } from './../../components/Layout'
import { Input, ValidacaoYup, SelectAsync, Select, Data, Checkbox } from './../../components/Form'

import api from './../../api'
import { Icon } from './../../components/Layout'

import { useAuth } from './../../context/Auth'

import './style.scss'

function Licenca() {

    const { getMenu } = useAuth()
    const menuShow = getMenu()

    /** Endereço */
    const [comboEndereco, setComboEndereco] = useState([])
    const [loadComboEndereco, setLoadComboEndereco] = useState(false)
    const [valueComboEndereco, setValueComboEndereco] = useState(null)

    const [isEdit, setIsEdit] = useState(false)
    const [bAlerta, setBAlerta] = useState(false)

    const [dataRamoAtividade, setDataRamoAtividade] = useState([])
    const [dataCondicionante, setDataCondicionante] = useState([])

    /** Anexos */
    const [dataAnexos, setDataAnexos] = useState([])

    const validaCampos = {
        cliente_id: ValidacaoYup.string().required(),
        tipo_regularizacao_id: ValidacaoYup.string().required(),
        localizacao_fisica_id: ValidacaoYup.string().required(),
        orgao_id: ValidacaoYup.string().required(),
        situacao_id: ValidacaoYup.string().required(),
        objeto_licenciado: ValidacaoYup.string().required(),
        numero_licenca: ValidacaoYup.string().max(30),
        numero_processo: ValidacaoYup.string().max(30),
    }

    // Endereço
    async function changeCliente(event) {

        if (!event) return

        setLoadComboEndereco(true)
        const resp = await api.get(`clienteendereco/combo/${event.value}`)

        setComboEndereco(resp.data)

        if (isEdit)
            setValueComboEndereco(resp.data.length > 0 ? resp.data[0] : null)

        setLoadComboEndereco(false)
    }

    function onChangeEndereco(event) {

        if (!event) return
        setValueComboEndereco(event)
    }

    // Ramo Atividade
    function onChangeRamoAtividade(event) {

        if (!event) return

        const existe = dataRamoAtividade.filter(ramo => +ramo.value === +event.value)
        if (existe.length === 0)
            setDataRamoAtividade([...dataRamoAtividade, event])
    }

    function onDeletaRamoAtividade(ramo) {

        const novalista = dataRamoAtividade.filter(ramoatividade => +ramoatividade.value !== +ramo.value)
        setDataRamoAtividade(novalista)
    }

    function ListaRamoAtividade() {

        return (
            <div className="row" style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 12 }}>
                {
                    dataRamoAtividade.map(ramo => {
                        return (
                            <div key={ramo?.value} className="list-item-dados">
                                <>{ramo?.label}</>
                                {
                                    String(menuShow) !== '4' &&
                                    <div>
                                        <span onClick={() => onDeletaRamoAtividade(ramo)}><Icon name='ti-trash font-16' /> Remover</span>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    function onSaveBefore(data) {

        /** Regra para gerar Alertas */
        const dataValidade = document.querySelector('#data_validade')
        if (dataValidade && dataValidade.value.length < 10 && bAlerta) {
            return {
                error: true,
                message: 'Para gerar Alertas a Data de validade deve estar preenchida'
            }
        }

        data.cliente_endereco_id = valueComboEndereco.value
        data.lista_ramo_atividade = dataRamoAtividade
        data.anexos = dataAnexos

        return data
    }

    function onEditarAfter(data, set, load, modal) {

        setIsEdit(false)

        setBAlerta(data.licenca.alerta)

        set(data.licenca)
        setDataRamoAtividade(data.ramo_atividade)
        setDataCondicionante(data.condicionantes)
        setDataAnexos(data.anexos)

        modal(true)
        load(false)
    }

    function onNovoBefore() {

        setIsEdit(true)
        setBAlerta(false)
        setDataRamoAtividade([])
        setDataCondicionante([])
        setValueComboEndereco(null)
        setDataAnexos([])
    }

    function onNovoAfter(setDataEdit) {

        setDataEdit({
            status: { value: 'A', label: 'Aberto' }
        })
    }

    function Condicionantes() {

        return (
            <table className='table table-hover table-vcenter'>
                <thead className='thead-light'>
                    <tr>
                        <th style={{ width: '55%' }}>Descrição</th>
                        <th>Data Prazo</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataCondicionante.map(cond => {
                            return (
                                <tr key={cond.id}>
                                    <td>{cond.descricao}</td>
                                    <td>{cond.data_prazo}</td>
                                    <td>{cond.status}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    /** Anexos */
    function ListaAnexos() {
        return (
            <>
                <Anexo
                    files={dataAnexos}
                    setFiles={setDataAnexos}
                    routeDelete='licenca/deleteanexo'
                    showSelectInputNewFile={String(menuShow) !== '4'}
                />

                {
                    dataAnexos.length === 0 &&
                    <div className='nofiles-alt'>
                        <img src='../assets/images/nofiles/nofile-anexo.svg' />
                        <span>Nenhum anexo encontrado</span>
                    </div>
                }
            </>
        )
    }    

    function FilterComponents() {

        return (
            <>
                <div className='row'>
                    <SelectAsync
                        name='cliente_id'
                        col='col-lg-12'
                        titulo='Cliente'
                        router='cliente/combo'
                    />
                </div>

                <div className='row'>

                    <Input
                        name='objeto_licenciado'
                        titulo='Objeto Licenciado'
                        placeholder='Digite o nome do Objeto '
                        col='col-lg-6'
                        maxLength={255} />

                    <Input
                        disabled={String(menuShow) === '4'}
                        type='text'
                        name='numero_processo'
                        titulo='Processo'
                        placeholder='Digite o Processo'
                        col='col-lg-6'
                        maxLength={30} />
                </div>

                <div className='row'>

                    <SelectAsync                        
                        name='situacao_id'
                        col='col-lg-6'
                        titulo='Situação Licenca'
                        router='situacao/combo' />

                    <Select                        
                        name='status'
                        titulo='Status'
                        col='col-lg-6'
                        options={[
                            { value: 'A', label: 'Aberto' },
                            { value: 'F', label: 'Fechado' },
                        ]} />
                </div>
            </>
        )
    }   

    return (
        <ContainerCad
            title='Licença'
            titlesub='Licença / Licença'
            router='licenca'
            disabledOnNovo={String(menuShow) === '4'}
            thead={[
                { nome: 'Cliente' },
                { nome: 'Objeto Licenciado', tamanho: '30%' },
                { nome: 'Número Processo' },
                { nome: '', tamanho: 50 },
                { nome: 'Criado por', tamanho: 150 },
                { nome: 'Situação', tamanho: 150 },
                { nome: 'Status', tamanho: 80 },
            ]}
            validacao={validaCampos}
            onNovoBefore={onNovoBefore}
            onNovoAfter={onNovoAfter}
            onSaveBefore={onSaveBefore}
            onEditarAfter={onEditarAfter}

            filterComponents={<FilterComponents />}
            showOptionExcel={true}
        >

            <Tab
                tabs={[
                    {
                        nome: 'Dados', body: (
                            <>
                                {/* Cliente */}
                                <div className="row">

                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='cliente_id'
                                        col='col-lg-8'
                                        titulo='Cliente'
                                        router='cliente/combo'
                                        onChange={changeCliente} />

                                    <Select
                                        isDisabled={String(menuShow) === '4'}
                                        name='status'
                                        titulo='Status'
                                        col='col-lg-4'
                                        options={[
                                            { value: 'A', label: 'Aberto' },
                                            { value: 'F', label: 'Fechado' },
                                        ]} />

                                </div>

                                {/* Endereço */}
                                <div className="row">
                                    <Select
                                        isDisabled={String(menuShow) === '4'}
                                        name='cliente_endereco_id'
                                        isLoading={loadComboEndereco}
                                        col='col-lg-12'
                                        titulo='Endereço'
                                        options={comboEndereco}
                                        value={valueComboEndereco}
                                        onChange={onChangeEndereco}
                                    />
                                </div>

                                {/* Licenca */}
                                <div className="row">
                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        type='text'
                                        name='numero_processo'
                                        titulo='Processo'
                                        placeholder='Digite o Processo'
                                        col='col-lg-6'
                                        maxLength={30} />

                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='tipo_regularizacao_id'
                                        col='col-lg-6'
                                        titulo='Tipo Regularização'
                                        router='tiporegularizacao/combo' />
                                </div>

                                <div className="row">
                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='ramo_atividade_id'
                                        onChange={onChangeRamoAtividade}
                                        col='col-lg-12'
                                        titulo='Ramo de Atividade'
                                        router='ramoatividade/combo' />
                                </div>

                                <ListaRamoAtividade />

                                <div className="row">
                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='localizacao_fisica_id'
                                        col='col-lg-12'
                                        titulo='Localização Fisíca'
                                        router='localizacaofisica/combo' />
                                </div>

                                <div className="row">
                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='orgao_id'
                                        col='col-lg-6'
                                        titulo='Orgão'
                                        router='orgao/combo' />

                                    <SelectAsync
                                        isDisabled={String(menuShow) === '4'}
                                        name='situacao_id'
                                        col='col-lg-6'
                                        titulo='Situação Licenca'
                                        router='situacao/combo' />
                                </div>

                                <div className="row">
                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        name='objeto_licenciado'
                                        titulo='Objeto Licenciado'
                                        placeholder='Digite o nome do Objeto '
                                        col='col-lg-12'
                                        maxLength={255} />
                                </div>

                                <div className="row">
                                    <Data disabled={String(menuShow) === '4'} name='data_formalizacao' titulo='Data Formalização' col='col-lg-4' />
                                    <Data disabled={String(menuShow) === '4'} name='data_decisao' titulo='Data Decisão' col='col-lg-4' />
                                    <Data disabled={String(menuShow) === '4'} name='data_validade' titulo='Data Validade' col='col-lg-4' />
                                </div>

                                <div className="row">
                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        name='ano_validade'
                                        titulo='Ano Validade'
                                        type='number'
                                        placeholder='Digite o Ano '
                                        col='col-lg-6' />

                                    <Input
                                        disabled={String(menuShow) === '4'}
                                        name='numero_licenca'
                                        titulo='Número Licença' type='text'
                                        placeholder='Digite o Número '
                                        col='col-lg-6' />
                                </div>

                                <div className='recorrente__cond'>

                                    <div className="row">
                                        <Checkbox
                                            disabled={String(menuShow) === '4'}
                                            name='alerta'
                                            titulo='Alertar sobre o vencimento'
                                            col='col-lg-12'
                                            onChange={() => setBAlerta(!bAlerta)} />
                                        <div className='explanacao__item'>
                                            Marque esta opção para que o sistema te lembre do vencimento desta Licença
                                            Adicione quantos dias quer ser lembrado antes do vencimento.
                                        </div>
                                    </div>

                                    {
                                        bAlerta &&
                                        <div className="row recorrente-traco">
                                            <Input
                                                disabled={String(menuShow) === '4'}
                                                name='alerta_dias'
                                                titulo='Dias antes do vencimento'
                                                col='col-lg-12'
                                                placeholder='0'
                                                type='number' />
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    },
                    { nome: 'Anexos', body: <ListaAnexos /> },
                    dataCondicionante.length > 0 &&
                    { nome: 'Condicionantes', body: <Condicionantes /> },
                ]}
            />

        </ContainerCad>
    )
}

export default Licenca