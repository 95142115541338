import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'

import {
    Form,
    Input,
    Button,
    ValidacaoYup,
    CNPJ,
    CPF,
    Celular,

} from './../../components/Form'
import { mensagemErro, mensagemSucesso } from './../../components/Layout'

import { cpf, cnpj } from 'cpf-cnpj-validator'

import { useAuth } from './../../context/Auth'
import { useLayout } from './../../context/Layout'
import { useHistory } from 'react-router-dom'

import api from './../../api'
import './style.scss'

import ReCAPTCHA from "react-google-recaptcha";

function Cadastro() {

    const [load, setLoad] = useState(false)
    const [cadastroRealizado, setCadastroRealizado] = useState(false)
    const [validacao, setValidacao] = useState('')

    const [cpfCnpj, setCpfCnpj] = useState('cnpj')

    const { setUser, getUser } = useAuth()
    const { isScreenLogin } = useLayout()
    const history = useHistory()

    const recaptchaRef = React.createRef();

    const validaCampos = {
        nome: ValidacaoYup.string().min(3).max(200).required(),
        email: ValidacaoYup.string().email().required(),
        celular: ValidacaoYup.string().required(),
    }

    async function onCadastro(data) {

        recaptchaRef.current.execute()

        try {

            const newCel = data.celular.replace(/\D/g, "");
            const cnpjcpfValid = data.cnpjcpf.replace(/\D/g, "");

            if (newCel.length < 11 || newCel.length > 11) {
                mensagemErro('Informe um número de celular válido')
                return
            }

            if (cpfCnpj === 'cpf') {

                if (!cpf.isValid(cnpjcpfValid)) {
                    mensagemErro('Informe um CPF válido')
                    return
                }
            }
            else {

                if (!cnpj.isValid(cnpjcpfValid)) {
                    mensagemErro('Informe um CNPJ válido')
                    return
                }
            }

            setLoad(true)
            const { cnpjcpf, nome, celular, email } = data

            const resp = await api.post('login/cadastro', {
                cnpjcpf,
                nome,
                celular,
                email
            })

            if (resp.data.type === 0) {

                mensagemSucesso('Cadastro realizado com sucesso, por favor valide no seu e-mail')
                history.push('codigo', { email: data.email })
            }
            else if (resp.data.type === 1) {
                setValidacao(resp.data.mensagem)
            }

            setLoad(false)

        } catch (error) {

            setLoad(false)
            mensagemErro('Ops, parece que algo não saiu como esperado, tente novamente mais tarde!')
        }
    }

    const formUser = () => (
        <>
            {
                <Form onSubmit={onCadastro} className="form-horizontal auth-form my-4" col='input-group mb-3' validacao={validaCampos}>

                    <div className='cadastro--titulo'>
                        <label>Cadastro</label>
                        <span>Crie seu cadastro grátis e já comece a usar o Ambientar agora mesmo!.</span>
                    </div>

                    <div>
                        <label style={{ marginBottom: 0 }}>Tipo</label>
                        <div className="escolheCnpjCpf">
                            <div onClick={() => setCpfCnpj('cnpj')} className={`eCnpj ${cpfCnpj === 'cnpj' && 'active'}`}>CNPJ</div>
                            <div onClick={() => setCpfCnpj('cpf')} className={`eCpf ${cpfCnpj === 'cpf' && 'active'}`}>CPF</div>
                        </div>
                    </div>

                    {
                        cpfCnpj === 'cpf' ?
                            <CPF name='cnpjcpf' titulo='CPF' placeholder='000.000.000-00' /> :
                            <CNPJ name='cnpjcpf' titulo='CNPJ' placeholder='00.000.000/0000-00' />
                    }

                    <Input name='nome' titulo='Nome' placeholder='Digite o Nome' maxLength={255} />
                    <Input name='email' titulo='E-mail' placeholder='Digite o e-mail' maxLength={255} />
                    <Celular name='celular' titulo='Celular' placeholder='(00) 0 0000-0000' />

                    <div className="form-group mb-0 row">
                        <div className="col-12 mt-2">
                            <Button load={load} name='Cadastrar' icon={<i className="fas fa-sign-in-alt ml-1"></i>} />
                            {validacao && <div className="validaRetorno">{validacao}</div>}
                        </div>
                    </div>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size='invisible'
                        sitekey="6LeEskMeAAAAAKx_X9fXZ8pJSQ2F4T4pkR5x91m9"
                        onChange={() => { }}
                    />
                </Form>
            }
        </>
    )

    function JaTemCadastro() {
        return (
            <div className="m-3 text-center text-muted">
                <p className=""><Link to='/' exact className="text-primary ml-2">Já tenho uma conta</Link></p>
            </div>
        )
    }

    return (
        <div className="row vh-100">
            <div className="col-12 align-self-center">
                <div className="auth-page login-container">
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
                        <div className="imgLogoClass" style={{ width: 200, marginBottom: -10 }} />
                        <p className='subtit' style={{ fontSize: 12 }}>GERENCIADOR DE LICENÇAS E CONDICIONANTES</p>
                    </div>
                    <div className="card auth-card shadow-lg">
                        <div className="card-body">
                            <div className="px-3">
                                {formUser()}
                            </div>
                        </div>
                        <JaTemCadastro />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cadastro