import React from 'react'
import Anexos from './Anexos'

const Condicionante = React.memo(({ cond }) => {

    return (
        <div className="card">
            <div className="card-body">
                <div className='cond-content'>

                    <div className='cond-tipo-info'>
                        {
                            cond.circleStatus === 'vencida' ?
                                <i className="ti-clipboard rounded-circle bg-soft-danger"></i> :
                                cond.circleStatus === 'vencendomes' ?
                                    <i className="ti-receipt rounded-circle bg-soft-success"></i> :
                                    <i className="ti-receipt rounded-circle bg-soft-secondary"></i>
                        }
                        <div className='cond-descricao'>
                            {cond.descricao}
                            {cond.observacao && <span className='cond-obs'>OBS: {cond.observacao}</span>}
                            {cond.cliente && <div className='cond-cliente'>CLIENTE: {`${cond.cliente} / ${cond.clienteemail}`}</div>}
                        </div>
                    </div>
                    <div className='cond-badges'>
                        <span className=''>ITEM: {cond.item}</span>
                        <span className='' style={{ marginLeft: 10 }}>ANEXO: {cond.anexo}</span>
                    </div>

                </div>
                {
                    cond.listaAnexos.length > 0 && <Anexos anexos={cond.listaAnexos} />
                }
                <div className='cond-footer'>
                    <div className='cond-user-info'>
                        <img src={cond.avatar} style={{ width: 20, height: 20, borderRadius: 10 }} />
                        <div className='cond-user-info-dados'>
                            <span>{cond.usuario}</span>
                            <span className='cond-user-nivel'>{cond.nivel}</span>
                        </div>
                    </div>
                    <div className='cond-data-info'>
                        <span style={{ color: cond.circleStatus === 'vencida' && '#f1646c' }}>{cond.data_prazo}</span>
                        <span className='cond-data-from' style={{ color: cond.circleStatus === 'vencida' && '#f1646c' }}>{cond.data_prazo_from}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Condicionante