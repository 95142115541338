import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Input, ValidacaoYup } from './../../components/Form'
import { Tab, Icon, mensagemErro, Anexo, Modal } from './../../components/Layout'

import ReactLoading from 'react-loading'

function RamoAtiviade() {

    /** Relad page */
    const [reloadPage, setReloadPage] = useState(false)

    /** Importar cliente */
    const [showModalImportRamo, setShowModalImportRamo] = useState(false)
    const [filesImport, setFilesImport] = useState([])

    /** Anexos */
    const [dataAnexos, setDataAnexos] = useState([])
    const [loadAnexo, setLoadAnexo] = useState(false)

    const validaCampos = {
        descricao: ValidacaoYup.string().required(),
        codigo_atividade: ValidacaoYup.string().required()
    }

    function onCompleteImportacao() {

        setLoadAnexo(false)
        setReloadPage(!reloadPage)
        setShowModalImportRamo(false)
    }

    function ImportClientes() {

        function onImport() {
            setShowModalImportRamo(true)
        }

        return (
            <button data-rh='IMPORTAR' data-rh-at='top' type="button" className="btn btn-primary" onClick={onImport} style={{ marginLeft: 8 }}><Icon name='ti-export' /></button>
        )
    }

    function FilterComponents() {

        return (
            <>
                <div className="row">
                    <Input name='descricao' titulo='Descrição' placeholder='Digite a descrição' col='col-lg-8' />
                    <Input name='codigo_atividade' titulo='Código Atividade' placeholder='Digite o Código' col='col-lg-4' />
                </div>
            </>
        )
    }

    return (
        <>
            <ContainerCad
                title='Ramo de Atividade'
                titlesub='Cadastro / Ramo de Atividade'
                router='ramoatividade'
                thead={[
                    { nome: 'Descrição' },
                    { nome: 'Código Atividade', tamanho: 150 },
                    { nome: 'Criado por', tamanho: 150 },
                ]}
                validacao={validaCampos}
                moreButtons={[ImportClientes]}
                reload={reloadPage}

                filterComponents={<FilterComponents />}
            >

                <div className="row">
                    <Input name='descricao' titulo='Descrição' placeholder='Digite a descrição' col='col-lg-8' />
                    <Input name='codigo_atividade' titulo='Código Atividade' placeholder='Digite o Código' col='col-lg-4' />
                </div>

            </ContainerCad>

            {
                showModalImportRamo &&
                <Modal
                    title='Importar Clientes'
                    onClose={() => setShowModalImportRamo(false)}
                    showButtonSave={false}
                    showButtonCancel={!loadAnexo}
                >

                    <Anexo
                        files={filesImport}
                        setFiles={setFilesImport}
                        routeSend='ramoatividade/importar'
                        showButtonDelete={false}
                        descricao='É aceito apenas CSV com até 15mb'
                        accept={['.csv']}
                        oneFile={true}
                        justImport={true}
                        iniciadoCB={() => setLoadAnexo(true)}
                        completeCB={onCompleteImportacao}
                    />

                    {
                        loadAnexo ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <ReactLoading type='bars' color='#20af62' />
                                <p>Aguarde, estamos importando todos os Ramos de Atividades</p>
                            </div> :
                            <>
                                <div className='download-csv-exemplo-import-cliente'>
                                    <Icon name='ti-download' />
                                    <a href={`${process.env.REACT_APP_SERV}/csv/Exemplo_Planilha_Ramo_Atividade.csv`} download>Download Planilha Exemplo.csv</a>
                                </div>
                                <div className='download-csv-info'>
                                    <span>{`Faça download da planilha acima, preenche conforme informado na mesma, depois basta fazer o upload
                                    dela nesta mesma tela. É necessário remover a linha com informações na plnilha de campos opcionais e obrigatórios.
                                    Observação importante, O Sistema irá validar a Descrição, em casos idênticos o Ambientar irá apenas ignorar o registro.`}</span>
                                </div>
                            </>
                    }

                </Modal>
            }
        </>
    )
}

export default RamoAtiviade