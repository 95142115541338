import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { cpf } from 'cpf-cnpj-validator'
import { mensagemErro } from './../../components/Layout'

function CPF({ name, col, titulo, ...props }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null || value === undefined ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });

        const _cpf = document.querySelector('#cpf')
        _cpf && _cpf.addEventListener('blur', function () {
            const _clearNumbers = String(_cpf.value).replace(/\D/g, '');
            if (_clearNumbers.length > 0 && !cpf.isValid(_clearNumbers)) {
                mensagemErro('Este CPF não é válido')
            }
        })

    }, [fieldName, registerField]);

    return (
        <div className={`cInputD ${col}`}>
            <label htmlFor={name}>{titulo}</label>
            <ReactInputMask id='cpf' mask='999.999.999-99' autoComplete='no' ref={inputRef} defaultValue={defaultValue} {...props} />
        </div>
    );
}

export default CPF