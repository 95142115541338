import React from 'react'
import './style.scss'
import ReactLoading from 'react-loading'

function Button({ load, name = 'SALVAR', icon }) {
    return (
        <>
            <button disabled={load ? load : false} className="btn btn-primary btn-block waves-effect waves-light" type="submit" style={{ display:'flex', justifyContent:'center', alignItems:'center' }} >
                {
                    load ?
                        <ReactLoading type='cylon' color='#FFF' height={30} width={30} />
                        :
                        <>
                            {
                                name
                            }
                            {
                                icon && icon
                            }
                        </>
                }
            </button>
        </>
    )
}

export default Button