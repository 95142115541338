import React, { useState } from 'react'

import './style.scss'
import { useLayout } from './../../../context/Layout'

import Parser from 'html-react-parser';


function Table({ thead, tbody, onEditar, onDelete, nofileimage }) {

    const { loadFull } = useLayout()

    function ButtonEditar({ onClick, disabled = false }) {

        return (
            <button onClick={onClick} className="mr-1 btn btn-sm btn-soft-info">
                {
                    !disabled ?
                        <i className="fas fa-pen"></i>
                        :
                        <i className="fas fa-eye"></i>
                }
            </button>
        )
    }

    function ButtonDeletar({ onClick, disabled = false }) {

        const [deletaValida, setDeletaValida] = useState(false)

        function onValidaDelete() {

            setDeletaValida(true)

            setTimeout(() => {
                setDeletaValida(false)
            }, 2000)
        }

        return (
            <>
                {
                    !deletaValida ?
                        <button disabled={disabled} onClick={onValidaDelete} className="btn btn-sm btn-soft-danger">
                            <i className="far fa-trash-alt"></i>
                        </button> :
                        <button disabled={disabled} onClick={onClick} className="btn btn-sm btn-soft-success">
                            <i className="fas fa-check"></i>
                        </button>
                }

            </>
        )
    }

    return (
        <>
            {
                <table className="table table-hover table-vcenter">

                    <thead className='thead-light'>
                        <tr>
                            {
                                thead.map((head, index) => {

                                    let thshow

                                    index === 0 ?
                                        thshow = <th key={index}>{head.nome}</th>
                                        :
                                        thshow = <th key={index} className="d-none d-sm-table-cell" style={{ width: head.tamanho ? head.tamanho : '15%' }}>{head.nome}</th>

                                    return thshow
                                })
                            }
                            <th className="text-center" style={{ width: 100 }}>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tbody.map((tr, index) => {

                                let showButtonEditar = tr.showEdit ? tr.showEdit : false
                                let showButtonDelete = tr.showDelete ? tr.showDelete : false

                                return (
                                    <tr key={index}>
                                        {
                                            tr.dados.map((td, index) => {

                                                let tdShow
                                                let newTd                                                

                                                if (String(td).indexOf('http') !== -1) {
                                                    newTd = <div>
                                                        <img src={td} style={{ width: 30, heigth: 30, borderRadius: 15, cursor: 'pointer' }} />
                                                    </div>
                                                }
                                                else {
                                                    if (td)
                                                        newTd = Parser(String(td))
                                                }

                                                index === 0 ?
                                                    tdShow = <td key={index}>{newTd}</td>
                                                    :
                                                    tdShow = <td key={index} className='d-none d-sm-table-cell'>{newTd}</td>

                                                return tdShow
                                            })
                                        }
                                        <td className="text-center">
                                            <div className="btn-group">
                                                {
                                                    <ButtonEditar
                                                        disabled={!showButtonEditar}
                                                        onClick={() => onEditar(tr.id, showButtonEditar)}
                                                    />
                                                }
                                                {
                                                    showButtonDelete &&
                                                    <ButtonDeletar
                                                        onClick={() => onDelete(tr.id)}
                                                    />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table >
            }
            {
                tbody.length === 0 && !loadFull &&
                <div className="nofilestable">
                    <img src={`../assets/images/nofiles/${nofileimage ? nofileimage : 'nofile-table.svg'}`} />
                </div>
            }
        </>
    )
}

export default Table