import React from 'react'

import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import { Icon } from './../../../components/Layout'
import Card from './Card'

import { useAuth } from './../../../context/Auth'

const DouInfo = React.memo(({ load, dataDou }) => {

    const { getMenu } = useAuth()
    const history = useHistory()

    const menuShow = getMenu()

    function onOpenPageDou() {

        document.querySelector('.dou-menu').click()
        history.push('/dou')
    }

    function LoadDou() {
        return (
            <>
                <div className='dou-dash'>
                    <div className='dou-dash-cliente'>
                        <Skeleton width={20} height={20} style={{ marginRight: 6 }} />
                        <Skeleton width={100} height={20} />
                    </div>
                    <div className='dou-dash-header'>
                        <div><Skeleton width='80%' height={20} /></div>
                        <div className='dou-dash-data'>
                            <Skeleton width={20} height={20} style={{ marginRight: 6 }} />
                            <Skeleton width={80} height={20} />
                        </div>
                    </div>
                    <div className='dou-dash-descricao'><Skeleton width='96%' height={14} /></div>
                    <div className='dou-dash-link'>
                        <Skeleton width={180} height={30} />
                    </div>
                </div>
            </>
        )
    }

    function SemDou() {
        return (
            <div className='nofiles-dash-dou' style={{ width: '100%' }} >
                <img src='../assets/images/nofiles/nofile-dou.svg' style={{ width: 230 }} />
                <span>Nenhum dou encontrado</span>
            </div>
        )
    }

    return (
        <Card>
            <div className="card">
                <div className="card-header">
                    <Icon name='ti-package' />
                    Último DOU encontrado
                </div>
                <div className="card-body">
                    {
                        load ? <LoadDou /> :
                            dataDou.length === 0 ? <SemDou /> :
                                <>
                                    <div className='dou-dash'>
                                        <div className='dou-dash-cliente'>
                                            <Icon name='ti-user' />
                                            {dataDou.cliente}
                                        </div>
                                        <div className='dou-dash-header'>
                                            <div>{dataDou.titulo}</div>
                                            <div className='dou-dash-data'>
                                                <Icon name='ti-calendar' />
                                                {dataDou.data_consulta}
                                            </div>
                                        </div>
                                        <div className='dou-dash-descricao'>{dataDou.descricao}</div>
                                        <div className='dou-dash-link'>
                                            <a href={dataDou.link} target='blank'>
                                                <Icon name='ti-link' />
                                            Link para acesso do DOU
                                        </a>
                                        </div>
                                    </div>
                                </>
                    }
                </div>
                {
                    dataDou.length !== 0 && String(menuShow) !== '4' &&
                    <div className='card-footer' onClick={onOpenPageDou}>
                        <Icon name='ti-angle-down' />
                        ver todos
                    </div>
                }
            </div>
        </Card>
    )
})

export default DouInfo