import React, { useContext, createContext, useState } from 'react'

const LayoutContext = createContext()

function LayoutProvider({ children }) {

    const [loadFull, setLoadFull] = useState(false)
    const [loadFullOff, setLoadFullOff] = useState(false)

    const [menuPin, setMenuPin] = useState('dashboard')
    const [openMenu, setOpenMenu] = useState(false)

    function isScreenLogin(login) {
        if (login) {
            document.body.classList.add('account-body')
            document.body.classList.add('accountbg')
        }
        else {
            document.body.classList.remove('accountbg')
            document.body.classList.remove('account-body')
        }
    }

    function setModalOpen(open, func) {

        if (open) {
            document.body.classList.add('modal-open')
        }
        else {
            document.body.classList.remove('modal-open')
        }

        if (func)
            func()
    }

    return (
        <LayoutContext.Provider value={{
            isScreenLogin,
            menuPin,
            setMenuPin,
            openMenu,
            setOpenMenu,
            loadFull,
            setLoadFull,
            setModalOpen,
            loadFullOff,
            setLoadFullOff
        }}>
            {children}
        </LayoutContext.Provider>
    )
}

function useLayout() {
    const context = useContext(LayoutContext)
    return context
}

export {
    LayoutContext,
    LayoutProvider,
    useLayout
}