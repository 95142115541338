import React, { useState, useEffect } from 'react'

import { ContainerCad } from './../../components/Container'
import { Input, ValidacaoYup } from './../../components/Form'

import { TwitterPicker } from 'react-color'

function Situacao() {

    const [color, setColor] = useState('#FFFFFF')

    const validaCampos = {
        descricao: ValidacaoYup.string().required()
    }

    function onSaveBefore(data) {

        if (color === '') {
            return {
                error: true,
                message: 'Preencha a cor para continuar'
            }
        }

        data.cor = color.hex
        return data
    }

    function onEditarAfter(data, setData, setLoad, showModal) {

        setColor(data.cor)

        setData(data)
        setLoad(false)
        showModal(true)
    }

    return (
        <ContainerCad
            title='Situação Licença'
            titlesub='Cadastro / Situação'
            router='situacao'
            thead={[
                { nome: 'Descrição' },
                { nome: 'Cor', tamanho: 150 },
                { nome: 'Criado por', tamanho: 150 },
            ]}
            validacao={validaCampos}
            onSaveBefore={onSaveBefore}
            onEditarAfter={onEditarAfter}
        >

            <div className="row">
                <Input name='descricao' titulo='Descrição' placeholder='Digite a descrição' col='col-lg-12' />
            </div>

            <div className="row" style={{ marginLeft: 6, marginTop: 8, display: 'flex', flexDirection: 'column' }}>
                <label>Cor da Situação</label>
                <TwitterPicker
                    triangle='hide'
                    color={color}
                    onChangeComplete={color => setColor(color)}
                />
            </div>

        </ContainerCad>
    )
}

export default Situacao