import React, { useState, useEffect } from 'react'
import './style.scss'

import { ContainerCad } from './../../components/Container'
import { mensagemErro } from './../../components/Layout'
import { Input, ValidacaoYup, Pass, Select } from './../../components/Form'

function Colaborador() {

    const validaCampos = {
        nome: ValidacaoYup.string().required(),        
        email: ValidacaoYup.string().email().required(),
        status: ValidacaoYup.string().required()
    }

    const optionsStatus = [
        { value: 'A', label: 'Ativo' },
        { value: 'I', label: 'Inativo' },
    ]

    function validacaoOthers(data) {

        if (data.senha.length > 0 && data.repeteSenha.length === 0) {
            mensagemErro('Preencha as duas senhas para continuar')
            return false
        }

        if (data.senha.length === 0 && data.repeteSenha.length > 0) {
            mensagemErro('Preencha as duas senhas para continuar')
            return false
        }

        if (data.senha.length > 0 && data.repeteSenha.length > 0) {

            if (data.senha !== data.repeteSenha) {
                mensagemErro('As senhas devem ser iguais')
                return false
            }
        }

        delete data.repeteSenha
        return data
    }

    function onNovoAfter(setData) {

        setData({
            status: { value: 'A', label: 'Ativo' }
        })
    }

    return (
        <ContainerCad
            title='Colaborador'
            titlesub='Configuração / Colaborador'
            router='colaborador'
            thead={[
                { nome: 'Nome' },
                { nome: 'E-mail' },                
                { nome: 'Avatar', tamanho: 50 },
                { nome: 'Status', tamanho: 150 },
            ]}
            validacao={validaCampos}
            validacaoOthers={validacaoOthers}
            onNovoAfter={onNovoAfter}
        >
            <>
                <div className="row">
                    <Input name='nome' titulo='Nome' placeholder='Digite o Nome' col='col-lg-8' />
                    <Select name='status' titulo='Status' col='col-lg-4' options={optionsStatus} />
                </div>

                <div className="row">
                    <Input name='email' titulo='E-mail' placeholder='Digite o E-mail' col='col-lg-12' />
                </div>

                <div className="row">
                    <Pass name='senha' titulo='Senha' placeholder='Digite a Senha' col='col-lg-6' />
                    <Pass name='repeteSenha' titulo='Repete Senha' placeholder='Repira a Senha' col='col-lg-6' />
                </div>
            </>
        </ContainerCad>
    )
}

export default Colaborador