import React, { useState } from 'react'
import './style.scss'

import { Modal } from './../../components/Layout'
import { Input, SelectAsync, ValidacaoYup, CEP } from './../../components/Form'

import { v1 as geradorID } from 'uuid'

import api from './../../api'

function Endereco({ onClose, dataEndereco, setDataEndereco, dataEdit }) {

    const [cidadeValue, setCidadeValue] = useState('')

    async function onSave(data) {

        if (!data?.id)
            data.id = geradorID()

        data.delete = false

        // busca id cidade
        if (document.querySelector('#cidadeSet')) {
            const cidadeid = await api.post('cidade/buscaIdCidade', { cidade: document.querySelector('#cidadeSet').getAttribute('cidade') })
            data.cidade_id = Number(cidadeid.data)
        }

        const existe = dataEndereco.filter(endereco => String(endereco.id) === String(data.id))
        if (existe) {

            let outrosEnderecos = dataEndereco.filter(endereco => String(endereco.id) !== String(data.id))
            outrosEnderecos.push(data)
            setDataEndereco(outrosEnderecos)
        }
        else {

            setDataEndereco([...dataEndereco, data])
        }

        onClose()
    }

    const validaCampos = {
        referencia: ValidacaoYup.string().required(),
        cep: ValidacaoYup.string().required(),
        rua: ValidacaoYup.string().required(),        
        bairro: ValidacaoYup.string().required(),
        // cidade_id: ValidacaoYup.string().required()
    }

    return (
        <Modal
            title='Endereço'
            onClose={onClose}
            onSave={onSave}
            validacao={validaCampos}
            nomeButtonSave='Adicionar'
            data={dataEdit}
        >

            <div className="row">
                <Input name='referencia' col='col-lg-12' titulo='Referência' placeholder='Digite a referência' />
            </div>

            <div className="row">
                <CEP name='cep' col='col-lg-4' titulo='CEP' placeholder='00000-00' set={setCidadeValue} />
                <Input name='rua' col='col-lg-8' titulo='Rua' placeholder='Digite a Rua' />
            </div>

            <div className="row">
                <Input name='numero' type='number' col='col-lg-6' titulo='Número' placeholder='Digite o Número' />
                <Input name='complemento' col='col-lg-6' titulo='Complemento' placeholder='Digite o Complemento' />
            </div>

            <div className="row">
                <Input name='bairro' col='col-lg-6' titulo='Bairro' placeholder='Digite o Bairro' />
                <SelectAsync name='cidade_id' labelValue='cidade_nome' col='col-lg-6' titulo='Cidade' router='cidade/combo' placeholder='' />
            </div>

            <div className="row">
                <Input name='latitude' col='col-lg-6' titulo='Latitude' placeholder='Exemplo: -20.153471' />
                <Input name='longitude' col='col-lg-6' titulo='Longitude' placeholder='Exemplo: -44.900075' />
            </div>

            <div className="row">
                <Input name='obs' col='col-lg-12' titulo='Observação' placeholder='Digite a Observação' />
            </div>

        </Modal>
    )
}

export default Endereco