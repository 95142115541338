import React, { useEffect } from 'react'
import './../style.scss'
import './style.scss'

import { NavLink, Redirect } from 'react-router-dom'
import { Icon } from './../../Layout'

import { useLayout } from './../../../context/Layout'
import { useAuth } from './../../../context/Auth'

function Sidebar({
    data, // dados enviados de uma outra tela
    markerSelecionado, // Usado para identificar qual marker no mapa foi selecionado
}) {

    const { menuPin, setMenuPin, openMenu, setModalOpen } = useLayout()
    const { getUser, getMenu } = useAuth()

    const usuario = getUser()
    const menuShow = getMenu()

    useEffect(() => {

        setModalOpen(menuPin === 'mapa')

    }, [menuPin])

    function MenuIcon() {
        return (
            <>
                <nav className="nav nav-menu">

                    <a
                        className={`nav-link ${menuPin === 'dashboard' && 'active'}`}
                        onClick={() => setMenuPin('dashboard')}
                        data-rh='DASHBOARD'
                    >
                        <Icon name='ti-layout-grid2' />
                    </a>
                    
                    <a
                        className={`nav-link ${menuPin === 'licenca' && 'active'}`}
                        onClick={() => setMenuPin('licenca')}
                        data-rh='LICENÇAS'
                    >
                        <Icon name='ti-layout-media-right-alt' />
                    </a>
                    
                    {
                        String(menuShow) === '1' || String(menuShow) === '2' ?
                            <a
                                className={`nav-link ${menuPin === 'cadastro' && 'active'}`}
                                onClick={() => setMenuPin('cadastro')}
                                data-rh='CADASTROS'
                            >
                                <Icon name='ti-folder' />
                            </a> : undefined
                    }

                    {
                        String(menuShow) === '1' || String(menuShow) === '2' ?
                            <a
                                className={`nav-link ${menuPin === 'mapa' && 'active'}`}
                                onClick={() => setMenuPin('mapa')}
                                data-rh='MAPA'
                            >
                                <Icon name='ti-map-alt' />
                            </a> : undefined
                    }

                    {
                        String(menuShow) === '1' || String(menuShow) === '2' ?
                            <a
                                className={`nav-link ${menuPin === 'dou' && 'active'} dou-menu`}
                                onClick={() => setMenuPin('dou')}
                                data-rh='INTEGRAÇÃO'
                            >
                                <Icon name='ti-package' />
                            </a> : undefined
                    }

                    <a
                        className={`nav-link ${menuPin === 'config' && 'active'}`}
                        onClick={() => setMenuPin('config')}
                        data-rh='CONFIG.'
                    >
                        <Icon name='ti-settings' />
                    </a>

                </nav>
            </>
        )
    }

    const NavLinkMenu = React.memo(({ children, to, icon, activeClassName }) => {
        return (
            <li className='nav-item'>

                <NavLink to={to} activeClassName={activeClassName} className='nav-link'>
                    {icon && <Icon name={icon} />}
                    {children}
                </NavLink>
            </li>
        )
    })

    return (
        <>
            {
                !openMenu &&
                <div className="left-sidenav">
                    <div className="main-icon-menu">
                        <MenuIcon />
                    </div>

                    <div className="main-menu-inner">
                        <div className="menu-body slimscroll">

                            {
                                menuPin === 'dashboard' &&

                                <div className="main-icon-menu-pane active mm-active" style={{ height: '100%' }}>
                                    <div className="dadosUser-dash">
                                        <div className='dadosUser-dash-dados'>
                                            <img src={usuario.avatar} className='rounded-circle' />
                                            <span className='dadosUser-dash-nome'>{usuario.nome.length >= 20 ? `${usuario.nome.substr(0, 16)}...` : usuario.nome}</span>
                                            <span className='dadosUser-dash-email'>{usuario.email.length > 35 ? usuario.email.substring(0,33) + '...' : usuario.email }</span>
                                        </div>
                                        <div className='dadosUser-dash-acesso'>
                                            {
                                                String(menuShow) === '1' ?
                                                    <p className="mb-0 p-2 bg-soft-dark" style={{ marginTop: 10, borderRadius: 2 }}><b>Plano: </b> ACESSO TOTAL</p>
                                                    : undefined
                                            }
                                        </div>
                                    </div>
                                    <Redirect to='/dash'></Redirect>
                                </div>
                            }

                            {
                                menuPin === 'cadastro' &&
                                <div className="main-icon-menu-pane active mm-active">
                                    <div className="title-box active">
                                        <h6 className="menu-title">Cadastro</h6>
                                    </div>
                                    <ul className="nav in mm-show">
                                        <NavLinkMenu to='/ramoatividade' icon='ti-write' activeClassName='active'>Ramo Atividade</NavLinkMenu>
                                        <NavLinkMenu to='/orgao' icon='ti-medall' activeClassName='active'>Órgão Público</NavLinkMenu>
                                        <NavLinkMenu to='/tiporegularizacao' icon='ti-marker' activeClassName='active'>Tipo Regularização</NavLinkMenu>
                                        <NavLinkMenu to='/localizacaofisica' icon='ti-pin' activeClassName='active'>Localização Fisica</NavLinkMenu>
                                        {
                                            String(menuShow) === '1' ?
                                                <NavLinkMenu to='/situacao' icon='ti-flag' activeClassName='active'>Situação Licença</NavLinkMenu>
                                                : undefined
                                        }
                                    </ul>
                                </div>
                            }

                            {
                                menuPin === 'licenca' &&
                                <div className="main-icon-menu-pane active mm-active">
                                    <div className="title-box active">
                                        <h6 className="menu-title">Lançamento</h6>
                                    </div>
                                    <ul className="nav in mm-show">
                                        { String(menuShow) !== '4' ? <NavLinkMenu to='/cliente' icon='ti-user' activeClassName='active'>Cliente</NavLinkMenu> : undefined }
                                        <NavLinkMenu to='/licenca' icon='ti-clipboard' activeClassName='active'>Licença</NavLinkMenu>
                                        <NavLinkMenu to='/condicionante' icon='ti-receipt' activeClassName='active'>Condicionantes</NavLinkMenu>
                                    </ul>
                                </div>
                            }


                            {
                                menuPin === 'mapa' &&
                                <>
                                    <div className="main-icon-menu-pane active mm-active">
                                        <div className="title-box active">
                                            <h6 className="menu-title">Clientes</h6>
                                        </div>
                                        {
                                            data && data.length === 0 ? 'Nenhum menu' :
                                                <ul className="nav in mm-show">
                                                    <div className='menu-mapa-lista'>
                                                        {
                                                            data && data.map((menu, index) => {
                                                                return (
                                                                    <div key={index} className={`menu-mapa ${menu === markerSelecionado ? 'menu-mapa-marcado' : ''}`} onClick={menu.onClick}>
                                                                        <div>
                                                                            <div>{menu.nome}</div>
                                                                            <div>{`${menu.rua}, ${menu.numero} - ${menu.complemento}, ${menu.bairro} - ${menu.cidade}/${menu.uf}`}</div>
                                                                        </div>
                                                                        <i className='ti-angle-right'></i>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </ul>
                                        }
                                    </div>
                                    <Redirect to='/mapa'></Redirect>
                                </>
                            }

                            {
                                menuPin === 'dou' &&
                                <div className="main-icon-menu-pane active mm-active">
                                    <div className="title-box active">
                                        <h6 className="menu-title">Integração</h6>
                                    </div>
                                    <ul className="nav in mm-show">
                                        <NavLinkMenu to='/dou' icon='ti-folder' activeClassName='active'>DOU</NavLinkMenu>
                                    </ul>
                                </div>
                            }

                            {
                                menuPin === 'config' &&
                                <div className="main-icon-menu-pane active mm-active">
                                    <div className="title-box active">
                                        <h6 className="menu-title">Configuração</h6>
                                    </div>
                                    <ul className="nav in mm-show">
                                        {
                                            String(menuShow) === '1' ?
                                                <>
                                                    <NavLinkMenu to='/moderador' icon='ti-view-list' activeClassName='active'>Moderador</NavLinkMenu>
                                                    <NavLinkMenu to='/colaborador' icon='ti-user' activeClassName='active'>Colaborador</NavLinkMenu>
                                                </>
                                                : undefined
                                        }

                                        {String(menuShow) !== '4' ? <NavLinkMenu to='/ticket' icon='ti-comments' activeClassName='active'>Ticket</NavLinkMenu> : undefined}
                                        <NavLinkMenu to='/perfil' icon='ti-user' activeClassName='active'>Perfil</NavLinkMenu>
                                    </ul>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Sidebar